import { LOGOUT } from 'types/app.constants';
import { mergePayloadChanges } from 'helpers/reducer.helper';
import { LOAD_D2P_DATA } from 'types/d2p.constants';

const initialState = {
  planCode: null,
  planConfig: null,
  direct2Premium: null,
  loading: false,
  postSignUpData: null,
  d2pTracking: null,
};

export default function d2pReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_D2P_DATA:
      return mergePayloadChanges(state, action);
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}
