import { useEffect, useMemo } from 'react';
import { useLogger } from 'hook/error.hook';
import { getCreditReportTab } from 'actions/creditReportTab.actions';
import { createModulesContainer } from '../helper/creditReportContainer';
import { completeUrl, getGetParam } from 'helpers/domHelper';
import { isRegisteredUser } from 'helpers/userHelper';
import { isFree } from 'helpers/userHelper';

export const useCreditReportModules = props => {
  const { config } = props;
  const logger = useLogger({
    name: 'useCreditReportTUContainer.hook',
  });
  const stateofLocation =
    props.history?.location?.search || props.history?.location?.state;
  const hasDiscrepanciesByParam = stateofLocation
    ? getGetParam('showDiscrepancyModule', completeUrl(stateofLocation))
    : null;
  const disputeDashboardIncludedByParam = stateofLocation
    ? getGetParam('disputeDashboardIncluded', completeUrl(stateofLocation))
    : null;

  const {
    dispatch,
    userInfo,
    creditReportState,
    disputesState,
    tabEventCalled,
  } = props || {};
  const { creditReportTabContainer } = props?.creditReportTabContainer || {};

  useEffect(() => {
    if (
      isRegisteredUser(userInfo) &&
      !creditReportTabContainer &&
      hasDiscrepanciesByParam === null
    ) {
      dispatch(getCreditReportTab()).catch(error => {
        logger.reportAPIError(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, creditReportTabContainer, hasDiscrepanciesByParam]);

  const {
    modules,
    hasCreditReports,
    hasDiscrepancies,
    hasCreditLock,
    disputeDashboardIncluded,
  } = useMemo(
    _ => {
      return createModulesContainer(props, config);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      userInfo,
      creditReportTabContainer,
      creditReportState,
      disputesState,
      tabEventCalled,
    ],
  );

  return {
    modules,
    hasCreditReports,
    hasDiscrepancies: hasDiscrepancies || hasDiscrepanciesByParam === 'true',
    hasCreditLock,
    disputeDashboardIncluded:
      disputeDashboardIncluded || disputeDashboardIncludedByParam === 'true',
    isFreeUser: isFree(userInfo),
  };
};
