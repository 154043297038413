//Morpheus
import { getBankingUrl, getTrkClickUrl, getSlapiUrl } from 'helpers/env';

const notificationAlertsDetails = {
  CREDIT_MONITORING: '/credit-monitoring/',
  CUSTOM_CREDIT_MONITORING: '/custom-alert/',
  ID_PROTECTION: '/id-protection/',
  TIME_SENSITIVE: '/custom-alert/',
};

function appendQueryParams(baseUrl, params) {
  // Create a URL object from the base URL
  const url = new URL(baseUrl);

  // Iterate over the params object and append each key-value pair to the URL's search parameters
  Object.keys(params).forEach(key => {
    url.searchParams.set(key, params[key]);
  });

  // Return the complete URL with query parameters
  return url.toString();
}

export const buildUserEndpoint = vriVersion =>
  vriVersion
    ? `${getSlapiUrl()}/users?vriVersion=${vriVersion}`
    : `${getSlapiUrl()}/users`;
export const buildSessionEndpoint = (isPreScreen = false) =>
  `${getSlapiUrl()}/sessions?realTimeAO=true&prescreen=${isPreScreen}`;
export const buildChatSessionEndpoint = () => `${getSlapiUrl()}/sessions/chat`;
export const buildOcfSessionEndpoint = (isPreScreen = false) =>
  `${getSlapiUrl()}/sessions/ocf?prescreen=${isPreScreen}`;
export const buildAccessTokenEndpoint = () =>
  `${getSlapiUrl()}/users/access-token`;
export const buildMigratedUserEndpoint = () =>
  `${getSlapiUrl()}/migrated-users`;
export const buildMigratedUserEmailEndpoint = () =>
  `${getSlapiUrl()}/migrated-users/email`;
export const buildMigratedUserPhoneEndpoint = () =>
  `${getSlapiUrl()}/migrated-users/phone-number`;
export const buildMigratedUserPasswordEndpoint = () =>
  `${getSlapiUrl()}/migrated-users/password`;
export const buildUserAddressEndpoint = () =>
  `${getSlapiUrl()}/users/addresses`;
export const buildCreditProfileEndpoint = () =>
  `${getSlapiUrl()}/users/credit-profiles`;
export const buildCreditProfileEndpointWithDetail = () =>
  `${getSlapiUrl()}/users/credit-profiles?details=true&trends=true`;
export const buildCreditScoreRefreshEndpoint = () =>
  `${getSlapiUrl()}/users/credit-profiles?score_only=true&force_refresh=true`;
export const buildCreditScore3BDial = () =>
  `${getSlapiUrl()}/users/credit-score/csid/3b`;
export const buildUpdateTradelineEndpoint = idHash =>
  `${getSlapiUrl()}/users/credit-profiles/tradelines/${idHash}`;
export const buildBannerViewedEndpoint = bannerId =>
  `${getSlapiUrl()}/users/banners/${bannerId}/viewed`;
export const buildClientConfigEndpoint = () =>
  `${getSlapiUrl()}/client-configuration`;
export const buildCreditCardEndpoint = () =>
  `${getSlapiUrl()}/products/credit-cards`;
export const buildSingleCreditCardEndpoint = creditCardId =>
  `${getSlapiUrl()}/products/credit-cards/${creditCardId}`;
export const buildSingleCreditCardReviewsEndpoint = creditCardId =>
  `${getSlapiUrl()}/products/credit-cards/${creditCardId}/reviews`;
export const buildCreditCardReviewsInventoryEndpoint = () =>
  `${getSlapiUrl()}/products/credit-cards/reviews/ccInventory`;
export const buildCreditCardPreapprovalEndpoint = () =>
  `${getSlapiUrl()}/products/credit-cards/pre-approvals`;
export const buildPersonalLoanEndpoint = () =>
  `${getSlapiUrl()}/products/personal-loans`;
export const buildPersonalLoanPreapprovalEndpoint = () =>
  `${getSlapiUrl()}/products/personal-loans/pre-approvals`;
export const buildPrequalFormDataEndpoint = vertical =>
  `${getSlapiUrl()}/users/products/pre-approvals/form-data?vertical=${vertical}`;
export const buildSinglePersonalLoanEndpoint = personalLoanId =>
  `${getSlapiUrl()}/products/personal-loans/${personalLoanId}`;
export const buildSinglePersonalLoanReviewsEndpoint = () =>
  `${getSlapiUrl()}/products/personal-loans/reviews`;
export const buildAutoLoanEndpoint = () =>
  `${getSlapiUrl()}/products/auto-loans`;
export const buildSingleAutoLoanEndpoint = lenderId =>
  `${getSlapiUrl()}/products/auto-loans/${lenderId}`;
export const buildRewardsCalculationEndpoint = () =>
  `${getSlapiUrl()}/products/credit-cards/rewards-calculation`;
export const buildMortgageEndpoint = () =>
  `${getSlapiUrl()}/products/mortgages`;
export const buildOffersEndpoint = () => `${getSlapiUrl()}/offers`;
export const buildFeaturedAdsEndpoint = () =>
  `${getSlapiUrl()}/offers/feature-ad`;
export const buildCreditRepairEndpoint = () =>
  `${getSlapiUrl()}/products/credit-repair`;
export const buildInHouseSimulationEndpoint = () =>
  `${getSlapiUrl()}/users/simulations/inhouse`;
export const buildSesameCashEnrollEndpoint = () => `${getSlapiUrl()}/banking`;
export const buildSesameCashEnrollLaterEndpoint = () =>
  `${getSlapiUrl()}/banking/enroll-later`;
export const buildSesameCashPinTokenEndpoint = () =>
  `${getSlapiUrl()}/banking/pin-token`;
export const buildUpdateCredentialsEndpoint = () =>
  `${getSlapiUrl()}/users/credentials`;
export const buildPasswordResetEndpoint = () =>
  `${getSlapiUrl()}/users/password-reset`;
export const buildverifyOTPDeviceVerification = () =>
  `${getSlapiUrl()}/users/password-reset/verify-otp`;
export const buildPasswordResetWithTokenEndpoint = token =>
  `${getSlapiUrl()}/users/password-reset/${token}`;
export const buildPasswordResetWithTokenSSNEndpoint = token =>
  `${getSlapiUrl()}/users/password-reset/${token}/verify-ssn`;
export const buildPasswordResetSendOTPEndpoint = () =>
  `${getSlapiUrl()}/users/password-reset/send-otp`;
export const buildPasswordResetValidateOTPEndpoint = () =>
  `${getSlapiUrl()}/users/password-reset/validate-otp`;
export const buildPasswordResetForgotEmailEndpoint = () =>
  `${getSlapiUrl()}/users/password-reset/forgot-email`;
export const buildPasswordResetRetriveForgottenEmail = () =>
  `${getSlapiUrl()}/users/password-reset/retrieve-forgotten-email`;
export const buildUserDetailsDMEndpoint = () => `${getSlapiUrl()}/direct-mail`;
export const buildUserVerifySSNEndpoint = () =>
  `${getSlapiUrl()}/users/verify-ssn`;
export const buildUserIdVerificationEndpoint = () =>
  `${getSlapiUrl()}/users/id-verification`;
export const buildUserIdVerificationSubmittedEndpoint = () =>
  `${getSlapiUrl()}/users/id-verification/submitted`;

export const buildCreditMonitoringEndpoint = () =>
  `${getSlapiUrl()}/users/alerts/credit-monitoring`;

export const buildNotificationsEndpoint = () =>
  `${getSlapiUrl()}/users/notifications`;

export const buildNotificationsDetailsEndpoint = (notificationType, alertId) =>
  `${getSlapiUrl()}/users/notifications${
    notificationAlertsDetails[notificationType]
  }${alertId}/details`;

export const buildValidateEmailChange = () =>
  `${getSlapiUrl()}/self-serve/user-info/validate`;
export const buildVerifyPhoneEmailChange = () =>
  `${getSlapiUrl()}/self-serve/challenge`;
export const buildSendCodeEmailChange = purpose =>
  `${getSlapiUrl()}/self-serve/verify?purpose=${purpose}`;
export const buildResetCredentialsEmailChange = () =>
  `${getSlapiUrl()}/self-serve/credentials/reset`;
export const buildVerifyEmailChange = () =>
  `${getSlapiUrl()}/self-serve/verify-change-email`;
export const buildSelfServeChangeEmail = () =>
  `${getSlapiUrl()}/self-serve/change-email`;

export const buildOCSPrefillStart = () =>
  `${getSlapiUrl()}/users/prefill/start`;
export const buildOCSVerifyFingerprint = () =>
  `${getSlapiUrl()}/users/prefill/verification-fingerprint`;
export const buildOCSContinueAuth = () =>
  `${getSlapiUrl()}/users/prefill/continue-auth`;
export const buildOCSAuthPhone = () =>
  `${getSlapiUrl()}/users/prefill/authenticate-phone`;
export const buildOCSGetIdentityURL = () =>
  `${getSlapiUrl()}/users/prefill/verified-by-auth-url/get-identity`;
export const buildOCSGetIdentityLTE = () =>
  `${getSlapiUrl()}/users/prefill/verified-by-redirect/get-identity`;
export const buildOCSVerifyIdentity = () =>
  `${getSlapiUrl()}/users/prefill/verify-identity`;
export const buildOCSPrefillFinish = () =>
  `${getSlapiUrl()}/users/prefill/finish`;
export const buildOCSPrefillVerifyOtp = () =>
  `${getSlapiUrl()}/users/prefill/verify-otp`;
export const buildOCSPrefillAuthStatus = () =>
  `${getSlapiUrl()}/users/prefill/auth-status`;
export const buildOCSEligibility = () =>
  `${getSlapiUrl()}/users/prefill/eligible`;
export const buildCompleteFreeAccountRegistration = () =>
  `${getSlapiUrl()}/users/complete-registration`;

export const buildVerifyPhoneChange = () =>
  `${getSlapiUrl()}/self-serve/verify-change-phone`;
export const buildSelfServeChangePhone = () =>
  `${getSlapiUrl()}/self-serve/change-phone`;
export const buildPartnerDecrypt = () =>
  `${getSlapiUrl()}/partner-encrypted/decrypt`;

export const buildBoosterWaitlist = () =>
  `${getSlapiUrl()}/banking/me/booster-waitlist`;

export const buildLinkToken = () =>
  `${getSlapiUrl()}/users/bank-account/link-token`;
export const buildPayrollSwitchAuthorization = () =>
  `${getSlapiUrl()}/banking/payroll-switch/authorized-url`;

export const buildCSIDEndpoint = () => `${getSlapiUrl()}/users/alerts/csid`;
export const buildAllNotificationsEndpoint = () =>
  `${getSlapiUrl()}/users/alerts/custom-alert`;
export const buildEnrollPremium = () => `${getSlapiUrl()}/users/subscriptions`;
export const buildValidateCouponCode = () =>
  `${getSlapiUrl()}/users/subscriptions/coupons/validation`;
export const buildCoupons = () =>
  `${getSlapiUrl()}/users/subscriptions/coupons`;
export const buildActivePremiumSubscription = () =>
  `${getSlapiUrl()}/users/subscriptions?status=ACTIVE`;
export const buildCancelPremiumSubscription = () =>
  `${getSlapiUrl()}/users/subscriptions/cancelSubscription?cancelImmediate=false`;
export const buildCancelPremiumSubscriptionImmediate = () =>
  `${getSlapiUrl()}/users/subscriptions/cancelSubscription?cancelImmediate=true`;
export const buildPremiumSubscriptionReactivationEndpoint = () =>
  `${getSlapiUrl()}/users/subscriptions/reactivation`;
export const buildInstantPurchase = () =>
  `${getSlapiUrl()}/users/instant-purchase`;
export const buildInstantPurchaseUpgrade = () =>
  `${getSlapiUrl()}/users/instant-purchase?isUpgradeNow=true`;
export const buildExperianAccountDetails = () =>
  `${getSlapiUrl()}/users/subscriptions/account`;
export const buildCreditReport = () => `${getSlapiUrl()}/users/credit-reports`;
export const buildCreditReportForCurrentMonth = () =>
  `${getSlapiUrl()}/users/credit-reports/current-month`;
export const build1BCreditReportForCurrentMonth = () =>
  `${getSlapiUrl()}/users/credit-reports/current-month/1b`;
export const buildCreditReportForReportId = reportId =>
  `${getSlapiUrl()}/users/credit-reports/${reportId}`;

export const build3BCreditReportForCurrentMonth = () =>
  `${getSlapiUrl()}/users/credit-reports/current-month/3b`;
export const build3BCreditReportForReportId = reportId =>
  `${getSlapiUrl()}/users/credit-reports/3b/${reportId}`;
export const build1BCreditReportForReportId = reportId =>
  `${getSlapiUrl()}/users/credit-reports/1b/${reportId}`;
export const buildCreditReportDiscrepancies = reportId =>
  `${getSlapiUrl()}/users/credit-reports/3b/${reportId}/discrepancies`;
export const buildUpdateDiscrepancy = () =>
  `${getSlapiUrl()}/users/credit-reports/3b/discrepancies`;
export const buildCreditReportDisputes = disputeId => {
  if (disputeId) {
    return `${getSlapiUrl()}/users/credit-reports/3b/discrepancies/disputes/${disputeId}`;
  } else {
    return `${getSlapiUrl()}/users/credit-reports/3b/discrepancies/disputes`;
  }
};

export const buildSubscriptionBillingEndpoint = () =>
  `${getSlapiUrl()}/users/subscriptions/billing`;
export const buildCouponRedeemEndpoint = () =>
  `${getSlapiUrl()}/users/subscriptions/coupons/redeem`;

export const buildKickboxVerification = () =>
  `${getSlapiUrl()}/email-verification`;
export const buildSendEmailEndpoint = () => `${getSlapiUrl()}/users/emails`;
export const buildInvitationReferrerEndpoint = referrerCode =>
  `${getSlapiUrl()}/users/invitations/referrers/${referrerCode}`;

export const buildAffiliatePixels = app =>
  `${getSlapiUrl()}/users/pixels?app=${app}`;

export const buildThirdPartyBankingApi = () =>
  `${getBankingUrl()}.getstack.ca/profile`;
export const buildPayrollToken = () =>
  `${getBankingUrl()}.getstack.ca/payroll/token`;
export const buildCashCardDetailsApi = () =>
  `${getBankingUrl()}.getstack.ca/card/details/physical`;

export const buildAllCardDetailsApi = () =>
  `${getBankingUrl()}.getstack.ca/card/details`;

export const buildCashPinApi = () => `${getBankingUrl()}.getstack.ca/cardPin`;

export const buildStackStory = () =>
  `${getBankingUrl()}.getstack.ca/stackStory/you`;

export const buildStackStoryRecent = () =>
  `${getBankingUrl()}.getstack.ca/stackStory/you/recent`;

export const buildStackBankTransfers = () =>
  `${getBankingUrl()}.getstack.ca/bankTransfers`;

export const buildStackBankTransfersV2 = () =>
  `${getBankingUrl()}.getstack.ca/bankTransfers/V2?status=PENDING`;

export const buildCashCardFreezeApi = () =>
  `${getBankingUrl()}.getstack.ca/card/freeze`;

export const buildCashCardUnfreezeApi = () =>
  `${getBankingUrl()}.getstack.ca/card/unfreeze`;

export const buildFundTransferB2CApi = () =>
  `${getBankingUrl()}.getstack.ca/fundTransfer/B2C`;

export const buildFundTransferC2BApi = () =>
  `${getBankingUrl()}.getstack.ca/fundTransfer/C2B`;

export const buildFinicityLinkToken = () =>
  `${getBankingUrl()}.getstack.ca/linkToken/V2`;
export const buildFinicityLinkedAccountsApi = () =>
  `${getBankingUrl()}.getstack.ca/bankAccount/linked/v2`;

export const buildFinicityInstitutionDetailsApi = institutionId =>
  `${getBankingUrl()}.getstack.ca/institution-details/V2/${institutionId}`;

export const buildSecuredCardEndpoint = () =>
  `${getBankingUrl()}.getstack.ca/card/secured-card`;
export const buildOptInSecuredCard = () =>
  `${getBankingUrl()}.getstack.ca/secured-card/opt-in`;

export const buildOptInSecuredCardV2 = () =>
  `${getBankingUrl()}.getstack.ca/secured-card/v2/opt-in`;
export const buildSecuredCardSettings = () =>
  `${getBankingUrl()}.getstack.ca/secured-card/settings`;
export const buildProfileUIState = () =>
  `${getBankingUrl()}.getstack.ca/profile/uiState`;
export const buildStackSecuredCardStatements = () =>
  `${getBankingUrl()}.getstack.ca/secured-card/statement`;
export const buildStackSecuredCardStatementDetail = id =>
  `${getBankingUrl()}.getstack.ca/secured-card/statement/${id}`;
export const buildSecuredCardTransactions = () =>
  `${getBankingUrl()}.getstack.ca/secured-card/transactions/recent`;
export const buildPurseWithdraw = () =>
  `${getBankingUrl()}.getstack.ca/secured-card/purse/withdraw`;
export const buildBankingConfig = () => `${getBankingUrl()}.getstack.ca/config`;
export const buildSecuredCardPayment = () =>
  `${getBankingUrl()}.getstack.ca/secured-card/payment`;
export const buildCardActivate = () =>
  `${getBankingUrl()}.getstack.ca/card/activate`;
export const buildDebtReliefEndpoint = () =>
  `${getSlapiUrl()}/products/debt-relief`;
export const build2FAChallenge = () => `${getSlapiUrl()}/users/2fa/challenge`;
export const buildDeviceIdCheckChangeEmail = () =>
  `${getSlapiUrl()}/self-serve/change-email?otpOption=VALIDATE_OTP`;
export const buildDeviceIdCheckChangePhone = () =>
  `${getSlapiUrl()}/self-serve/change-phone?otpOption=VALIDATE_OTP`;
export const build2FAVerify = () => `${getSlapiUrl()}/users/2fa/verify`;
export const build2FAPromote = () => `${getSlapiUrl()}/sessions/promote`;
export const build2FATrustedDevices = () =>
  `${getSlapiUrl()}/users/2fa/trusted-devices`;
export const buildTwoFactorVerifySSN = () =>
  `${getSlapiUrl()}/users/validate-last4-ssn`;

export const buildAlexaPin = () => `${getSlapiUrl()}/alexa/pin`;
export const buildAlexaAuthorize = () => `${getSlapiUrl()}/oauth/authorize`;
export const buildPostCreditCardReviewEndpoint = creditCardId =>
  `${getSlapiUrl()}/products/credit-cards/${creditCardId}/reviews`;
export const buildPostPersonalLoanReviewsEndpoint = personalLoanLenderId =>
  `${getSlapiUrl()}/products/personal-loans/${personalLoanLenderId}/reviews`;

export const buildGetDupeOTP = mediaType =>
  `${getSlapiUrl()}/users/dup-ssn-otp?mediaType=${mediaType}`;
export const buildValidateDupeOTP = () =>
  `${getSlapiUrl()}/users/answer/dup-ssn-otp/`;
export const buildDupKBASendCode = () => `${getSlapiUrl()}/users/2fa/send-otp`;
export const buildDupKBAPhoneVerification = () =>
  `${getSlapiUrl()}/users/2fa/verify-otp`;
export const buildDupKBAPhoneActivateUser = () =>
  `${getSlapiUrl()}/users/questions-ssn-match/activate-user`;

export const buildRentPaymentReport = () => `${getSlapiUrl()}/rent-reporting`;
export const buildPostRentReportingAuthentication = () =>
  `${getSlapiUrl()}/rent-reporting/sessions?purpose=AUTHENTICATION`;
export const buildPostRentRentalInfo = purpose =>
  `${getSlapiUrl()}/rent-reporting/sessions?purpose=${purpose}`;
export const buildGetRentPaymentInfo = () =>
  `${getSlapiUrl()}/rent-reporting/payments`;

export const buildUserQuestionaireEndpoint = () =>
  `${getSlapiUrl()}/users/questionnaires`;

export const buildUpdateUserQuestionaireEndpoint = questionnaireId =>
  `${getSlapiUrl()}/users/questionnaires/${questionnaireId}/answers`;

export const buildPersonalizationEndpoint = () =>
  `${getSlapiUrl()}/personalization/offers`;

export const buildConvertFreeTrialEndpoint = () =>
  `${getSlapiUrl()}/users/subscriptions/convert-to-paid`;
export const buildConvertFreeTrialUpgradeEndpoint = () =>
  `${getSlapiUrl()}/users/subscriptions/convert-to-paid?isUpgradeNow=true`;

export const apiUrls = {
  login: realTimeAO => {
    return realTimeAO
      ? `${getSlapiUrl()}/sessions?realTimeAO=true`
      : `${getSlapiUrl()}/sessions`;
  },
  logout: () => `${getSlapiUrl()}/sessions/logout`,
  user: (params = null) =>
    params
      ? appendQueryParams(`${getSlapiUrl()}/users`, params)
      : `${getSlapiUrl()}/users`,
  register: () => `${getSlapiUrl()}/users`,
  updateUserAddress: function () {
    return `${getSlapiUrl()}/users/addresses`;
  },
  updateUserSSN: function () {
    return `${getSlapiUrl()}/users/ssn`;
  },
  updateUserPassword() {
    return `${getSlapiUrl()}/users/password`;
  },
  questions() {
    return `${getSlapiUrl()}/users/questions`;
  },
  questionsSSNMatch() {
    return `${getSlapiUrl()}/users/questions-ssn-match`;
  },
  verifyEmail() {
    return `${getSlapiUrl()}/emails/email-verifications`;
  },
  getAlertSettings() {
    return `${getSlapiUrl()}/users/settings/alerts`;
  },
  updateAlertSettings() {
    return `${getSlapiUrl()}/users/settings/alerts`;
  },
  patchAlertSettings(userId) {
    return `${getSlapiUrl()}/users/settings/alerts?userId=${userId}`;
  },
};

export const getHighLightFeatureEndpoint = () =>
  `${getSlapiUrl()}/highlight-features`;

export const wwwUrls = {
  buildTrkEndpoint: () => `${getTrkClickUrl()}/trk/click`,
};

export const buildOfferTrackingEncodedLink = encodedLink =>
  `${getSlapiUrl()}/products/offers/${encodedLink}`;

//OCF
export const buildOcfGetOfferEndpoint = () => `${getSlapiUrl()}/users/ocf`;
export const buildOcfDataExperimentEndpoint = () =>
  `${getSlapiUrl()}/users/ocf/experiment`;

// INSURANCE
export const buildInsuranceOffersEndpoint = () =>
  `${getSlapiUrl()}/products/insurances`;

export const buildBusinessLoanEndpoint = () =>
  `${getSlapiUrl()}/products/business-loans`;

export const buildVerticalFormDataEndpoint = () =>
  `${getSlapiUrl()}/users/products/form-data`;

export const buildInsuranceUserPrefillEndpoint = () =>
  `${getSlapiUrl()}/products/insurances/users`;

//Legal Agreements
export const buildLegalAgreement = () =>
  `${getSlapiUrl()}/users/agreements/signed/dates`;
export const buildLegalAgreementForAgreementId = agreementId =>
  `${getSlapiUrl()}/users/agreements/signed/dates?id=${agreementId}`;

//CROA Terms and Conditions
export const buildGetCROATerms = () =>
  `${getSlapiUrl()}/users/agreements/latest`;
export const buildPostUserCROATerms = () => `${getSlapiUrl()}/users/agreements`;

export const buildUserSessionStatus = () =>
  `${getSlapiUrl()}/users/sessions/status`;

//PasswordLess Login
export const buildSessionValidateUser = () =>
  `${getSlapiUrl()}/sessions/validate-user`;

export const buildCreditAdviceEndpoint = () =>
  `${getSlapiUrl()}/users/credit-advice`;

export const buildStorageByAttributeName = attributeName =>
  `${getSlapiUrl()}/users/storage?attributeName=${attributeName}`;

export const buildStorage = () => `${getSlapiUrl()}/users/storage`;

export const buildGoalsEndpoint = () => `${getSlapiUrl()}/users/goals`;
export const buildGoalActionEndpoint = actionId =>
  `${getSlapiUrl()}/users/goals/actions/${actionId}`;

export const buildCustomerInfoToCreditCardPrequalification = () =>
  `${getSlapiUrl()}/products/credit-cards/pre-approvals`;

/** Quarterly Report Endpoint */
export const buildQuarterlyReportEndPoint = () =>
  `${getSlapiUrl()}/users/credit-reports/quarter-month`;

export const buildUsersAddressVerificationEndpoint = () =>
  `${getSlapiUrl()}/users/address-verification`;

export const buildUsersActivityEventInViewOffers = () =>
  `${getSlapiUrl()}/users/activity-event`;

export const buildUsersActivityEventTypeInOfferModule = eventType =>
  `${getSlapiUrl()}/users/activity-event/viewed?eventType=${eventType}`;

export const buildCreditLockEndpoint = cached => {
  if (cached) {
    return `${getSlapiUrl()}/users/credit-lock?cached=${cached}`;
  } else {
    return `${getSlapiUrl()}/users/credit-lock`;
  }
};

export const buildDecreseCreditCardBalance = decreaseCreditCardBalance =>
  `${getSlapiUrl()}/users/simulations/inhouse?decreaseCreditCardBalance=${decreaseCreditCardBalance}`;

export const buildInvoicesEndpoint = () =>
  `${getSlapiUrl()}/users/invoices?page=1&itemsPerPage=1000`;

export const buildInvoiceByIdEndpoint = invoiceId =>
  `${getSlapiUrl()}/users/invoices/${invoiceId}`;

export const buildVerifyPromotionEligibilityEndpoint = () =>
  `${getSlapiUrl()}/users/verify-promotion-eligibility`;
export const buildAuditLogEventEndpoint = eventType =>
  `${getSlapiUrl()}/users/tracking/audit-event?eventType=${eventType}`;
export const buildAcknowledgePrescreen = () =>
  `${getSlapiUrl()}/personalization/prescreen/acknowledge?partner=CAPITAL_ONE&vertical=CREDIT_CARD`;
