export const phoneNumberMask = '(999) 999-9999';
export const trimPhoneNumber = formattedNumber => {
  if (!formattedNumber) {
    return formattedNumber;
  }
  let phoneNum = formattedNumber.replace(/\s+/g, '');
  let result = phoneNum?.length > 9 && phoneNum?.match(/^(\+?1-?)?[()\d-]*$/);
  if (result) {
    return phoneNum?.replace(/([()-]|(^\+?1-?))/g, '');
  }
  return phoneNum;
};
export const formatPhoneWithDashes = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
  }
  return null;
};

export function formatPhoneNumber(phoneNumberString) {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ['(', match[1], ') ', match[2], '-', match[3]].join('');
  } else {
    return cleaned;
  }
}

export function unFormatPhoneNumber(val) {
  val = val
    .replace(/ /g, '')
    .replace(/[{()}]/g, '')
    .replace(/-/g, '')
    .replace(/_/g, '');
  return val;
}

export function maskPhoneNumberWithParenthesis(phoneNumber) {
  if (!phoneNumber) return '***-***-****';
  return phoneNumber
    .toString()
    .replace(/(\d{1,3})(\d{1,3})(\d{1,2})(\d{1,2})/g, '(***) ***-**$4');
}
