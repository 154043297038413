/* eslint-disable react-hooks/rules-of-hooks */
import Icon from '@ant-design/icons';
import { Menu } from 'antd';
import classnames from 'classnames';
import Badge from 'components/Badge/Badge';
import { FlexContainer } from 'components/Containers/FlexContainer/FlexContainer';
import Label from 'components/form/Label/Label';
import {
  buildPlanFromConfiguration,
  isTherePremiumBanner,
} from 'components/premium/helpers/premium.helpers';
import { Tag } from 'components/Tag';
import { filterBusinessTitle } from 'helpers/common';
import { getGetParam, hasParentWithClass } from 'helpers/domHelper';
import { containsString, isEmpty } from 'helpers/string.helper';
import {
  isInvisibleUser,
  isPremium,
  isPremiumPlatinumPlan,
  isPulse,
} from 'helpers/userHelper';
import {
  getHomeLoanLandingPageURL,
  useVri,
} from 'pages/Marketplace/offerPageContainer.hook';
import HighlightFeatureLabel from 'partial/HighlightFeatureLabel';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import { ReactComponent as AdvancedMonitoringFilled } from 'style/_assets/icons/advanced-monitoring-filled.svg';
import { ReactComponent as AdvancedMonitoring } from 'style/_assets/icons/advanced-monitoring-outline.svg';
import { ReactComponent as AlertsFilled } from 'style/_assets/icons/alerts-filled.svg';
import { ReactComponent as AlertsOutline } from 'style/_assets/icons/alerts-outline.svg';
import { ReactComponent as CreditReportFilled } from 'style/_assets/icons/credit-report-filled.svg';
import { ReactComponent as CreditReportOutline } from 'style/_assets/icons/credit-report-outline.svg';
import { ReactComponent as CreditLockFilled } from 'style/_assets/icons/credit-lock-filled.svg';
import { ReactComponent as CreditLockOutline } from 'style/_assets/icons/credit-lock-outline.svg';
import { ReactComponent as CreditManagerFilled } from 'style/_assets/icons/credit-manager-filled.svg';
import { ReactComponent as CreditManagerOutline } from 'style/_assets/icons/credit-manager-outline.svg';
import { ReactComponent as FullCreditReportFilled } from 'style/_assets/icons/full-report-filled.svg';
import { ReactComponent as FullCreditReport } from 'style/_assets/icons/full-report-outline.svg';
import { ReactComponent as HomeButtonFilled } from 'style/_assets/icons/home-filled.svg';
import { ReactComponent as HomeButton } from 'style/_assets/icons/home-outline.svg';
import { ReactComponent as SesameCashIconFilled } from 'style/_assets/icons/icon-sesame-cash-filled.svg';
import { ReactComponent as SesameCashIconOutline } from 'style/_assets/icons/icon-sesame-cash-outline.svg';
import { ReactComponent as IdMonitoringFilled } from 'style/_assets/icons/id-monitoring-filled.svg';
import { ReactComponent as IdMonitoring } from 'style/_assets/icons/id-monitoring-outline.svg';
import { ReactComponent as IDTheftInsurance } from 'style/_assets/icons/id-theft-insurance.svg';
import { ReactComponent as LiveSupportFilled } from 'style/_assets/icons/live-experts-filled.svg';
import { ReactComponent as LiveSupport } from 'style/_assets/icons/live-experts-outline.svg';
import { ReactComponent as MarketplaceButtonFilled } from 'style/_assets/icons/marketplace-filled.svg';
import { ReactComponent as MarketplaceButton } from 'style/_assets/icons/marketplace-outline.svg';
import { ReactComponent as PremiumIcon } from 'style/_assets/icons/premium_reg.svg';
import { ReactComponent as ProfileButtonFilled } from 'style/_assets/icons/profile-filled.svg';
import { ReactComponent as ProfileButton } from 'style/_assets/icons/profile-outline.svg';
import { ReactComponent as RentReportingIcon } from 'style/_assets/icons/rent-reporting-icon.svg';
import { ReactComponent as SignOut } from 'style/_assets/icons/sign-out.svg';
import { ReactComponent as WalletProtectionFilled } from 'style/_assets/icons/wallet-protection-filled.svg';
import { ReactComponent as WalletProtection } from 'style/_assets/icons/wallet-protection-outline.svg';
import { ReactComponent as CSLogo } from 'style/_assets/logos/CS_Logo_Blue.svg';
import { ReactComponent as CSLogoPremium } from 'style/_assets/logos/CS_Logo_Premium.svg';
import { ReactComponent as IDProtectionFilled } from 'style_assets/icons/id_protection_shield_filled.svg';
import { ReactComponent as IDProtectionOutline } from 'style_assets/icons/id_protection_shield_outline.svg';
import {
  FEATURE_NAME_ALERT_MONITORING,
  FEATURE_NAME_ID_PROTECTION,
  FEATURE_NAME_AUTO_LOANS,
  FEATURE_NAME_BUSINESS_LOANS,
  FEATURE_NAME_CREDIT_CARDS,
  FEATURE_NAME_CREDIT_LOCK,
  FEATURE_NAME_CREDIT_MANAGER,
  FEATURE_NAME_CREDIT_REPORT,
  FEATURE_NAME_HOME_LOANS,
  FEATURE_NAME_INSURANCE,
  FEATURE_NAME_MARKETPLACE,
  FEATURE_NAME_PERSONAL_LOANS,
  FEATURE_NAME_PROFILE,
  FEATURE_NAME_SESAMECASH,
} from 'types/highlightFeatures.constants';
import {
  AUTO_INSURANCE_VERTICAL_CARD,
  AUTO_INSURANCE_VERTICAL_VRI,
  AUTO_LOAN_VERTICAL,
  AUTO_LOAN_VERTICAL_CARD,
  AUTO_LOAN_VERTICAL_VRI,
  BUSINESS_LOANS_VERTICAL,
  BUSINESS_LOANS_VERTICAL_CARD,
  BUSINESS_LOANS_VERTICAL_VRI,
  CREDIT_CARD_VERTICAL,
  CREDIT_CARD_VERTICAL_CARD,
  CREDIT_CARD_VERTICAL_VRI,
  HOME_INSURANCE_VERTICAL_VRI,
  HOME_LOAN_VERTICAL_CARD,
  INSURANCE_VERTICAL,
  PERSONAL_LOAN_VERTICAL,
  PERSONAL_LOAN_VERTICAL_CARD,
  PERSONAL_LOAN_VERTICAL_VRI,
} from 'types/marketplaceTiles.constants';
import {
  BANKING_VERTICAL,
  MORTGAGE_VERTICAL,
  SUBSCRIPTION_VERTICAL,
} from 'types/mrph.vertical.constants';
import {
  PREMIUM_FEATURE_ADVANCED_MONITORING,
  PREMIUM_FEATURE_CREDIT_REPORT,
  PREMIUM_FEATURE_ID_MONITORING,
  PREMIUM_FEATURE_ID_THEFT_PROTECTION,
  PREMIUM_FEATURE_KEY_ID_THEFT_PROTECTION,
  PREMIUM_FEATURE_KEY_WALLET,
  PREMIUM_FEATURE_LIVE_EXPERTS,
  PREMIUM_FEATURE_RENT_REPORTING,
  PREMIUM_FEATURE_WALLET_PROTECTION,
} from 'types/premium.constants';
import 'components/navigation/MenuList/MenuList.scss';

import { usePremiumPaymentPendingAlert } from 'partial/OfferManager/modules/PremiumPaymentPendingAlert/hooks/premiumPaymentPendingAlert.hook';
import PremiumPaymentPendingAlertMenuListView from 'partial_OfferManager_modules_PremiumPaymentPendingAlert/PremiumPaymentPendingAlertMenuListView';

import { usePremiumReactivateCancel } from 'partial/OfferManager/modules/PremiumReactivateCancel/hooks/premiumReactivateCancel.hook';
import PremiumReactivateCancelMenuListView from 'partial/OfferManager/modules/PremiumReactivateCancel/PremiumReactivateCancelMenuListView';

import { ConditionalRendering } from 'helpers/conditionalRedering.helper';
import { useSeenModulePixel } from 'hook/helper.hook';
import PremiumReactivatePendingCancellationMenuListView from 'partial/PremiumReactivatePendingCancellationModal/components/PremiumReactivatePendingCancellationMenuListView';
import { usePremiumReactivatePendingCancellation } from 'partial/PremiumReactivatePendingCancellationModal/hooks/premiumReactivatePendingCancellation.hook';
import { Button } from 'components/form/Button';
import { PLUS_MENU_ITEM_LABEL } from 'types/plus.constants';
import { ReactComponent as CSLogoPlus } from 'style/_assets/logos/CSPlusLogo_White.svg';
import { isPlus } from 'helpers/userHelper';
import { OVERVIEW_URL } from 'types/mrph.routes.constants';
import { useSubscriptionLandingUrl } from 'hook/subscriptionLandingUrl.hook';

function useHandleFocus(ref, setter, maskOn, desktopSetter) {
  function handleClickOutside(event) {
    if (
      ref.current &&
      ref.current.contains &&
      !ref.current.contains(event.target) &&
      !hasParentWithClass(event.target, 'desktopHamberger')
    ) {
      if (typeof setter === 'function') {
        setter();
      } else if (typeof desktopSetter === 'function') {
        desktopSetter();
      }
    }
  }

  useEffect(() => {
    if (maskOn || setter) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  });
}

function SubNavLink({
  datatestid,
  text,
  navTo,
  isActive,
  highlightDot,
  isPlusUser,
}) {
  const isLinkActive = isActive();
  const subNavLinkClass = classnames('margL40', 'Body-2', {
    bold: isLinkActive,
    fontBlue100: isLinkActive,
  });
  return (
    <NavLink
      to={navTo}
      data-testid={datatestid ? `${datatestid}-link` : null}
      activeClassName="selected"
      isActive={isActive}
    >
      <FlexContainer className="flex-row justify-between format100 align-content-center align-items-center">
        <span
          data-testid={datatestid ? `${datatestid}-text` : null}
          className={`${subNavLinkClass} ${isPlusUser ? 'plusUserLabel' : ''}`}
        >
          {text}
        </span>
        {highlightDot}
      </FlexContainer>
    </NavLink>
  );
}

const RentReportingMenuItem = ({
  outlineIcon,
  filledIcon,
  datatestid,
  isActive,
  navLinkText,
  isPlusUser,
  collapsed,
}) => {
  return (
    <div
      data-testid={datatestid ? `${datatestid}-rentReporting-link` : null}
      className={`ant-menu-item no-link ${isActive ? 'selected' : ''}`}
    >
      <RentReportingIcon
        className={`menuButton outline rentReporting ${
          isPlusUser ? 'plusIcon' : ''
        } `}
        component={outlineIcon}
        role=""
        aria-label={navLinkText}
      />
      <RentReportingIcon
        className={`menuButton filled rentReporting ${
          isPlusUser ? 'plusIcon' : ''
        } `}
        component={filledIcon}
        role=""
        aria-label={navLinkText}
      />
      <span
        data-testid={datatestid ? `${datatestid}-rentReporting-text` : null}
        className={`Body-1 ${!collapsed && 'padL10'} ${
          isPlusUser ? 'plusNavLabel' : ''
        }`}
      >
        {navLinkText}
      </span>
    </div>
  );
};

const GetPremiumBanner = props => {
  const {
    collapsed,
    premiumReactivateCancelHook,
    seenPremiumReactivateCancelPixel,
    premiumReactivatePendingCancellationHook,
    seenPremiumReactivatePendingCancellationPixel,
    premiumPaymentPendingHook,
    isPreviouslyPremiumPlusFlow,
    onClickReactivatePlus,
    csTitle,
  } = props;

  if (isPreviouslyPremiumPlusFlow && !collapsed) {
    return (
      <>
        <GetPremiumLabel {...props} />
        <FlexContainer row alignItemsCenter className="marg10">
          <Button
            mrphType="primary"
            className="fullWidth"
            onClick={onClickReactivatePlus}
          >
            Reactivate Now
          </Button>
        </FlexContainer>
      </>
    );
  } else if (premiumReactivateCancelHook?.isPremiumReactivateCancelUser) {
    return (
      <>
        <GetPremiumLabel {...props} />
        <PremiumReactivateCancelMenuListView
          {...premiumReactivateCancelHook}
          collapsed={collapsed}
          datatestid={'premium-reactivate-cancel-nav'}
          moduleName="Premium reactivate nav banner- cancelled user"
          seenPixel={seenPremiumReactivateCancelPixel}
        />
      </>
    );
  } else if (
    premiumReactivatePendingCancellationHook?.isPremiumReactivatePendingCancellationUser
  ) {
    return (
      <>
        <div
          className={classnames(
            'text-12 bold system_neutral_n000_text padL10 m-padl2 toUpper',
            {
              reactivatePendingCancellationUser: Boolean(collapsed),
              letterSpacing0: csTitle === PLUS_MENU_ITEM_LABEL,
            },
          )}
        >
          {csTitle}
        </div>
        <PremiumReactivatePendingCancellationMenuListView
          {...premiumReactivatePendingCancellationHook}
          collapsed={collapsed}
          datatestid={'premium-reactivate-pending-cancellation-nav'}
          seenPixel={seenPremiumReactivatePendingCancellationPixel}
        />
      </>
    );
  } else if (premiumPaymentPendingHook?.isPremiumPaymentPendingUser) {
    return (
      <>
        <PremiumPaymentPendingAlertMenuListView
          {...premiumPaymentPendingHook}
          collapsed={collapsed}
          datatestid={'premium-payment-pending'}
          moduleName="Premium payment past due hamburger"
          csTitle={csTitle}
        />
      </>
    );
  } else {
    return null;
  }
};

const GetPremiumLabel = props => {
  const {
    isPremiumUser,
    getPremiumClass,
    userInfo,
    collapsed,
    onVisibilityChangeBottom,
    bottomShadowOffset,
    csTitle,
    isPlusUser,
  } = props;
  const premiumClass = getPremiumClass('premiumCreditReports', isPremiumUser);

  const premiumPacakgeTitle = userInfo?.premiumPlanInfo?.uiDisplayName;

  if (collapsed) {
    return (
      <div className={`FlexContainer flex-column premiumLabel`}>
        <div
          className={`FlexContainer premiumLabelInner margLRAuto ${premiumClass}`}
        >
          <PremiumIcon className={`premiumLabelIcon margLRAuto margT2 `} />
        </div>
        <div
          className={`text-8 margLRAuto margT2 ${premiumClass} ${
            isPlusUser ? 'isPlusNavLabelClass' : 'premiumLabelText'
          }`}
        >
          <VisibilitySensor
            onChange={onVisibilityChangeBottom}
            offset={{ bottom: bottomShadowOffset }}
            resizeCheck={true}
          >
            <div className="invisible-pixel" />
          </VisibilitySensor>
          {csTitle}
        </div>
      </div>
    );
  }
  return (
    <Tag extClass={premiumClass}>
      {isPremiumUser ? premiumPacakgeTitle : csTitle?.toUpperCase()}
    </Tag>
  );
};

const MenuList = props => {
  const {
    closeDesktopMenu,
    closeMobileMenu,
    collapsed,
    creditInfo,
    currentPath,
    datatestid,
    disclosureItemClicked,
    eventHandler,
    history,
    isOcfRole,
    maskOn,
    onClick,
    premiumDataConfig,
    unreadAlertCount,
    isPreviouslyPremiumPlusFlow,
    onClickReactivatePlus,
    plusLaunch,
    idProtectionExp,
    creditLockEnabled,
    creditManagerVisited,
    menuTermsPrivacyUrls,
  } = props;
  const wrapperRef = useRef('menu');
  useHandleFocus(wrapperRef, closeMobileMenu, maskOn, closeDesktopMenu);
  const { subscriptionLandingUrl } = useSubscriptionLandingUrl();

  const collapsedWidth = collapsed ? '40px' : '256px';
  const { userInfo, userInfoOcf } = useSelector(state => ({
    userInfo: state.userInfo.userInfo,
    userInfoOcf: state.ocfInfo?.ocfInfo?.userInfo,
  }));
  const isPulseUser = isPulse(userInfo);
  const isPremiumUser = isPremium(userInfo);
  const isPlusUser = isPlus(userInfo);
  const isPlusNavLabelClass = isPlusUser ? 'plusUserMenu' : '';
  const isPremiumPlatinumUser = isPremiumPlatinumPlan(userInfo);
  const serviceId = userInfo?.premiumPlanInfo?.serviceId;
  const csTitle = plusLaunch?.textMapped?.long?.title;

  const addIDProtectionMenuItem = idProtectionExp?.config?.enabled;

  const [scrollPosition, setScrollPosition] = useState('full');
  const bottomShadowOffset = collapsed ? 80 : 125;
  const location = useLocation();

  const { termsOfUseUrl, privacyPolicyUrl } = menuTermsPrivacyUrls ?? {};

  const getDashboardActive = () =>
    containsString(currentPath, 'dashboard') ||
    containsString(currentPath, 'factors');

  const isOfferSubLink = pathArray => {
    return pathArray[1] === 'offers' && pathArray.length > 2;
  };

  const getCreditCardActive = () =>
    containsString(currentPath, 'creditCardCategories') ||
    containsString(currentPath, 'creditcard');

  const getOfferVerticalActive = vertical => {
    const verticalPathParam = getGetParam('vertical');
    let active = false;
    if (vertical === 'creditCard' || vertical === 'CREDIT_CARD') {
      active = getCreditCardActive();
    } else if (vertical === 'businessLoan') {
      active = containsString(currentPath, 'business');
    } else if (verticalPathParam) {
      active = containsString(verticalPathParam, vertical);
    } else {
      active = containsString(currentPath, vertical);
    }
    return active;
  };

  const getOffersLinkActive = collapsed => {
    const active =
      (!collapsed && currentPath === '/offers') ||
      (collapsed && containsString(currentPath, 'offers'));
    return active;
  };

  const visibiltityChangeHandler = (isVisible, currentSide, oppositeSide) => {
    let scrollPositionInternal = scrollPosition;
    if (!isVisible) {
      if (scrollPosition === currentSide) {
        scrollPositionInternal = '';
      } else if (scrollPosition === 'full') {
        scrollPositionInternal = oppositeSide;
      }
    } else {
      if (scrollPosition === oppositeSide) {
        scrollPositionInternal = 'full';
      } else if (scrollPosition !== 'full') {
        scrollPositionInternal = currentSide;
      }
    }
    return scrollPositionInternal;
  };

  const onVisibilityChangeBottom = isVisible => {
    setScrollPosition(visibiltityChangeHandler(isVisible, 'bottom', 'top'));
  };

  const onVisibilityChangeTop = isVisible => {
    setScrollPosition(visibiltityChangeHandler(isVisible, 'top', 'bottom'));
  };

  const getOffersPath = () => {
    if (getCreditCardActive()) {
      return 'creditCard';
    } else if (getOfferVerticalActive('personalLoan')) {
      return 'personalLoan';
    } else if (getOfferVerticalActive('autoLoan')) {
      return 'autoLoan';
    } else if (getOfferVerticalActive('insurance')) {
      return 'insurance';
    }
    return 'offers';
  };

  const getSelectedKeyFromPath = currentPath => {
    const pathArray = currentPath.split('/');
    const parentPath = pathArray[1];
    const navParam = getGetParam('nav');
    if (navParam) {
      return navParam;
    } else if (getDashboardActive()) {
      return 'dashboard';
    } else if (isOfferSubLink(pathArray)) {
      return getOffersPath();
    }
    return parentPath;
  };

  const checkDuplicateTabActiveStatus = (path, key, premiumTab) => {
    const { pathname } = location;
    const navAttr = getGetParam('nav');

    const match = pathname && pathname.startsWith(path);
    if (premiumTab) {
      return key === navAttr && match;
    } else {
      return !navAttr ? match : false;
    }
  };
  const getPremiumLink = (originalUrl, key, feature, extraGetParams = '') => {
    let premiumLink = !isEmpty(feature)
      ? subscriptionLandingUrl + '?feature=' + feature
      : subscriptionLandingUrl;

    return isPremiumQualified(key)
      ? originalUrl + '?nav=' + key + extraGetParams
      : premiumLink;
  };

  const getPremiumClass = key => {
    return isPremiumQualified(key, true) ? 'qualified' : 'non-qualified';
  };

  const isPremiumQualified = (key, isDisabledStatus) => {
    const planMetadata = buildPlanFromConfiguration(
      premiumDataConfig?.config?.all_plans_metadata,
      serviceId,
    );

    if (isPremiumUser) {
      return planMetadata?.hasFeature(key);
    }

    return false;
  };
  const getDisplayPremiumFeature = key => {
    if (isPremiumUser) return isPremiumQualified(key) ? true : false;
    return true;
  };

  const bottomMenuClass = !isOcfRole ? 'bottomMenu' : 'bottomMenuOcf';
  const plusBottomClass = isPlusUser ? 'plusBottomMenu' : '';
  const topShadowClass =
    scrollPosition !== 'top' && scrollPosition !== 'full' ? 'topShadow' : '';
  const bottomShadowClass =
    scrollPosition !== 'bottom' && scrollPosition !== 'full'
      ? 'bottomShadow'
      : '';

  const homeLoanUrl = getHomeLoanLandingPageURL(creditInfo);

  const marketPlaceOfferTiles = [
    {
      text: 'Credit Cards',
      cardName: CREDIT_CARD_VERTICAL_CARD,
      url: '/offers/creditCardCategories',
      vertical: CREDIT_CARD_VERTICAL,
      featureToHighlight: FEATURE_NAME_CREDIT_CARDS,
      type: 'CREDIT_CARD',
      datatestid: 'cc',
      vriName: CREDIT_CARD_VERTICAL_VRI,
    },
    {
      text: 'Personal Loans',
      cardName: PERSONAL_LOAN_VERTICAL_CARD,
      url: '/offers?vertical=PERSONAL_LOAN',
      vertical: PERSONAL_LOAN_VERTICAL,
      featureToHighlight: FEATURE_NAME_PERSONAL_LOANS,
      type: 'PERSONAL_LOAN',
      datatestid: 'pl',
      vriName: PERSONAL_LOAN_VERTICAL_VRI,
    },
    {
      text: 'Auto Loans',
      cardName: AUTO_LOAN_VERTICAL_CARD,
      url: '/offers?vertical=AUTO_LOAN',
      vertical: AUTO_LOAN_VERTICAL,
      featureToHighlight: FEATURE_NAME_AUTO_LOANS,
      type: 'AUTO_LOAN',
      datatestid: 'al',
      vriName: AUTO_LOAN_VERTICAL_VRI,
    },
    {
      text: 'Insurance',
      cardName:
        AUTO_INSURANCE_VERTICAL_CARD /*Requirement says to use auto insurance tile order*/,
      url: '/offers/insurance',
      vertical: INSURANCE_VERTICAL,
      featureToHighlight: FEATURE_NAME_INSURANCE,
      type: 'insurance',
      datatestid: 'insurance',
      vriName: AUTO_INSURANCE_VERTICAL_VRI,
    },
    {
      text: 'Home Loans',
      cardName: HOME_LOAN_VERTICAL_CARD,
      url: homeLoanUrl,
      vertical: MORTGAGE_VERTICAL,
      featureToHighlight: FEATURE_NAME_HOME_LOANS,
      type: 'HOME_LOAN',
      datatestid: 'hl',
      vriName: HOME_INSURANCE_VERTICAL_VRI,
    },
    {
      text: 'Business',
      cardName: BUSINESS_LOANS_VERTICAL_CARD,
      url: '/offers?vertical=BUSINESS',
      vertical: BUSINESS_LOANS_VERTICAL,
      featureToHighlight: FEATURE_NAME_BUSINESS_LOANS,
      type: 'business',
      datatestid: 'bl',
      vriName: BUSINESS_LOANS_VERTICAL_VRI,
    },
  ];

  const offerTitles = filterBusinessTitle(creditInfo, marketPlaceOfferTiles);

  const { finalOfferTiles, loadingVri } = useVri(
    offerTitles,
    userInfo || userInfoOcf,
  );

  const loading = loadingVri;
  const isRentReportingNav = !collapsed && !isPulseUser;

  const redirectToRentReporting = () => {
    const rentReportingStatus = userInfo?.rentReporting?.status;
    const rentReportingSubStatus = userInfo?.rentReporting?.subStatus;
    onClick('Rent Reporting');
    if (isPremiumUser) {
      if (rentReportingStatus && rentReportingSubStatus) {
        history.push('/rentPaymentReportingHome');
      } else {
        history.push('/rentPaymentReporting');
      }
    } else {
      history.push(
        subscriptionLandingUrl + '?feature=' + PREMIUM_FEATURE_RENT_REPORTING,
      );
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const seenPremiumReactivateCancelPixel = useSeenModulePixel({
    'Module Name': 'Premium reactivate nav banner- cancelled user',
  });
  const seenPremiumReactivatePendingCancellationPixel = useSeenModulePixel({
    'Module Name': 'Premium Reactivate nav banner - pending user',
  });

  const premiumPaymentPendingHook = usePremiumPaymentPendingAlert(userInfo);

  const premiumReactivateCancelHook = usePremiumReactivateCancel({
    eventHandler,
    history,
  });

  const premiumReactivatePendingCancellationHook =
    usePremiumReactivatePendingCancellation({
      eventHandler,
      history,
      userInfo,
    });

  const showPremiumBanner = isTherePremiumBanner({
    collapsed,
    premiumReactivateCancelHook,
    premiumReactivatePendingCancellationHook,
    premiumPaymentPendingHook,
    isPreviouslyPremiumPlusFlow,
  });

  if (!userInfo && !isOcfRole) {
    return '';
  } else if (isInvisibleUser(userInfo)) {
    return (
      <div
        className={`menuListContainer  FlexContainer flex-column ${
          isPlusUser ? 'plusMenuListContainer' : ''
        }`}
        ref={wrapperRef}
      >
        <Menu
          mode="inline"
          theme="lite"
          inlineCollapsed={collapsed}
          className={`logoMenu d-hide ${isPlusUser ? 'plusMainmenu' : ''}`}
        >
          <Menu.Item
            key="8"
            className={`navbar-logo-mobile-container ${topShadowClass}`}
          >
            <NavLink
              to={OVERVIEW_URL}
              onClick={() => {
                onClick('Logo');
              }}
            >
              <div
                className={classnames('navbar-logo-mobile', {
                  padL12: isPremiumUser,
                })}
              >
                {isPremiumUser ? <CSLogoPremium /> : <CSLogo />}
              </div>
            </NavLink>
            {isPremiumUser && (
              <div className="premiumIcon">
                <PremiumIcon />
              </div>
            )}
          </Menu.Item>
        </Menu>
        <Menu
          mode="inline"
          theme="lite"
          inlineCollapsed={collapsed}
          selectedKeys={[getSelectedKeyFromPath(currentPath)]}
          className="mainMenu"
        >
          <div className="padT16 m-padT8"></div>
          <Menu.Item
            key={'dashboard'}
            onClick={() => {
              onClick('Overview');
            }}
          >
            <NavLink
              to={OVERVIEW_URL}
              data-testid={datatestid ? `${datatestid}-overview-link` : null}
              activeClassName="selected"
              isActive={getDashboardActive}
            >
              <div className="leftNavItemWrapper">
                <HomeButton
                  className={`menuButton outline ${
                    isPlusUser ? 'plusIcon' : ''
                  }`}
                  aria-label={collapsed ? 'Profile' : 'My Profile'}
                  role=""
                />
                <HomeButtonFilled
                  className="menuButton filled menuIconFilled"
                  aria-label={collapsed ? 'Profile' : 'My Profile'}
                  role=""
                />
                <span
                  data-testid={
                    datatestid ? `${datatestid}-overview-text` : null
                  }
                  className={`Body-1 ${!collapsed && 'padL10'} ${
                    isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                  }`}
                >
                  <VisibilitySensor
                    onChange={onVisibilityChangeTop}
                    offset={{ top: 70 }}
                    resizeCheck={true}
                  >
                    <div className="invisible-pixel" />
                  </VisibilitySensor>
                  {collapsed ? 'Profile' : 'My Profile'}
                </span>
              </div>
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="offers"
            onClick={() => {
              onClick('Marketplace', FEATURE_NAME_MARKETPLACE);
            }}
          >
            <NavLink
              to="/offers"
              data-testid={datatestid ? `${datatestid}-marketplace-link` : null}
              activeClassName="selected"
              isActive={() => getOffersLinkActive(collapsed)}
            >
              <div className="leftNavItemWrapper">
                <MarketplaceButton
                  className={`menuButton outline ${
                    isPlusUser ? 'plusIcon' : ''
                  }`}
                  role=""
                  aria-label="Offers"
                />
                <MarketplaceButtonFilled
                  className="menuButton filled menuIconFilled"
                  role=""
                  aria-label="Offers"
                />
                <span
                  data-testid={
                    datatestid ? `${datatestid}-marketplace-text` : null
                  }
                  className={`Body-1 ${!collapsed && 'padL10'} ${
                    isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                  }`}
                >
                  Offers
                </span>
              </div>
            </NavLink>
            <HighlightFeatureLabel
              featureToHighlight={FEATURE_NAME_MARKETPLACE}
            />
          </Menu.Item>
          {!loading &&
            finalOfferTiles?.map(item => {
              if (!collapsed && !isPremiumUser) {
                return (
                  <Menu.Item
                    key={item.type}
                    onClick={() => {
                      onClick(
                        item.text,
                        item.featureToHighlight,
                        item.vertical,
                      );
                    }}
                    className="subMenuItem"
                  >
                    <SubNavLink
                      datatestid={
                        datatestid ? `${datatestid}-${item.datatestid}` : null
                      }
                      text={item.text}
                      navTo={item.url}
                      isPlusUser={isPlusUser}
                      isActive={() => getOfferVerticalActive(item.type)}
                      highlightDot={
                        <HighlightFeatureLabel
                          featureToHighlight={item.featureToHighlight}
                        />
                      }
                    />
                  </Menu.Item>
                );
              } else {
                return null;
              }
            })}
          <Menu.Item
            key="cash"
            onClick={() => {
              onClick('cash', FEATURE_NAME_SESAMECASH, BANKING_VERTICAL);
            }}
          >
            <NavLink
              to="/cash"
              data-testid={datatestid ? `${datatestid}-profile-link` : null}
              activeClassName="selected"
            >
              <div className="leftNavItemWrapper">
                <SesameCashIconOutline
                  className={`menuButton outline ${
                    isPlusUser ? 'plusIcon' : ''
                  }`}
                  role=""
                  aria-label="Sesame Cash"
                />
                <SesameCashIconFilled
                  className="menuButton filled menuIconFilled"
                  role=""
                  aria-label="Sesame Cash"
                />
                <span
                  data-testid={datatestid ? `${datatestid}-profile-text` : null}
                  className={`sesameCashMenuText Body-1 ${
                    !collapsed && 'padL10'
                  }`}
                >
                  Sesame Cash
                </span>
              </div>
            </NavLink>
            <HighlightFeatureLabel
              featureToHighlight={FEATURE_NAME_SESAMECASH}
            />
          </Menu.Item>
          <Menu.Item
            key="profile"
            onClick={() => {
              onClick('Profile', FEATURE_NAME_PROFILE);
            }}
          >
            <NavLink
              to="/profile"
              data-testid={datatestid ? `${datatestid}-profile-link` : null}
              activeClassName="selected"
              isActive={() => checkDuplicateTabActiveStatus('/profile')}
            >
              <div className="leftNavItemWrapper">
                <ProfileButton
                  className={`menuButton outline ${
                    isPlusUser ? 'plusIcon' : ''
                  }`}
                  role=""
                  aria-label="Settings"
                />
                <ProfileButtonFilled
                  className="menuButton filled menuIconFilled"
                  role=""
                  aria-label="Settings"
                />
                <span
                  data-testid={datatestid ? `${datatestid}-profile-text` : null}
                  className={`Body-1 ${!collapsed && 'padL10'}`}
                >
                  Settings
                </span>
              </div>
            </NavLink>
            {!collapsed && (
              <HighlightFeatureLabel
                featureToHighlight={FEATURE_NAME_PROFILE}
              />
            )}
          </Menu.Item>
        </Menu>
        <Menu
          className={`${bottomMenuClass} ${bottomShadowClass} ${plusBottomClass} margB8 m-margB0 m-padB8`}
          mode="inline"
          theme="lite"
          inlineCollapsed={collapsed}
        >
          {!isOcfRole ? (
            <Menu.Item
              key="1"
              onClick={() => {
                onClick('Logout');
              }}
            >
              <FlexContainer>
                <NavLink
                  to="/logout?reason=MANUAL"
                  data-testid={datatestid ? `${datatestid}-logout-link` : null}
                  activeClassName="selected"
                >
                  <div className="leftNavItemWrapper">
                    <SignOut
                      className={`menuButton outline ${
                        isPlusUser ? 'plusIcon' : ''
                      }`}
                      role=""
                      aria-label="Sign Out"
                    />
                    <SignOut
                      className="menuButton filled menuIconFilled"
                      role=""
                      aria-label="Sign Out"
                    />
                    <span
                      data-testid={
                        datatestid ? `${datatestid}-logout-text` : null
                      }
                      className={`Body-1 ${!collapsed && 'padL10'}`}
                    >
                      Sign Out
                    </span>
                  </div>
                </NavLink>
                <span className="padr6"></span>
              </FlexContainer>
            </Menu.Item>
          ) : (
            <div className="pad8"></div>
          )}
          <div>
            <span
              className="policyList"
              data-testid={datatestid ? `${datatestid}-policy-text` : null}
            >
              <a
                className="otherLink inlineBlock"
                href={privacyPolicyUrl}
                target="_blank"
              >
                <Label
                  onClick={() => {
                    disclosureItemClicked('Privacy Policy');
                  }}
                  type="Body-2"
                  color={isPlusUser ? 'lBlue085' : 'fontBlue100'}
                  className="otherLink"
                >
                  Privacy Policy
                </Label>
              </a>
            </span>
            <span
              className="policyList"
              data-testid={datatestid ? `${datatestid}-policy-text` : null}
            >
              <a
                className="otherLink inlineBlock"
                href={termsOfUseUrl}
                target="_blank"
              >
                <Label
                  onClick={() => {
                    disclosureItemClicked('Terms of Use');
                  }}
                  type="Body-2"
                  color={isPlusUser ? 'lBlue085' : 'fontBlue100'}
                  className="otherLink"
                >
                  &nbsp;&nbsp; - &nbsp;&nbsp;Terms of Use
                </Label>
              </a>
            </span>
          </div>
        </Menu>
      </div>
    );
  } else {
    return (
      <div
        className={`menuListContainer FlexContainer flex-column ${
          isPlusUser ? 'plusMenuListContainer' : ''
        }`}
        style={{ width: collapsedWidth }}
        ref={wrapperRef}
      >
        <Menu
          mode="inline"
          theme="lite"
          inlineCollapsed={collapsed}
          className={`logoMenu d-hide ${isPlusUser ? 'plusMainmenu' : ''}`}
        >
          <Menu.Item
            key="8"
            className={`navbar-logo-mobile-container ${topShadowClass}`}
          >
            <NavLink
              to={OVERVIEW_URL}
              onClick={() => {
                onClick('Logo');
              }}
            >
              <div
                className={classnames('navbar-logo-mobile', {
                  padL12: isPremiumUser,
                })}
              >
                {isPlusUser ? (
                  <CSLogoPlus />
                ) : isPremiumUser ? (
                  <CSLogoPremium />
                ) : (
                  <CSLogo />
                )}
              </div>
            </NavLink>
            {isPremiumUser && (
              <div className="premiumIcon">
                <PremiumIcon />
              </div>
            )}
          </Menu.Item>
        </Menu>
        <Menu
          mode="inline"
          theme="lite"
          inlineCollapsed={collapsed}
          selectedKeys={[getSelectedKeyFromPath(currentPath)]}
          className={`mainMenu ${isPlusUser ? 'plusMainmenu' : ''}`}
        >
          <div className="padT16 m-padT8"></div>
          <Menu.Item
            key={'dashboard'}
            onClick={() => {
              onClick('Overview');
            }}
          >
            <NavLink
              to={OVERVIEW_URL}
              data-testid={datatestid ? `${datatestid}-overview-link` : null}
              activeClassName="selected"
              isActive={getDashboardActive}
            >
              <div className="leftNavItemWrapper">
                <HomeButton
                  className={`menuButton outline ${
                    isPlusUser ? 'plusIcon' : ''
                  }`}
                  role=""
                  aria-label={collapsed ? 'Profile' : 'My Profile'}
                />
                <HomeButtonFilled
                  className="menuButton filled menuIconFilled"
                  role=""
                  aria-label={collapsed ? 'Profile' : 'My Profile'}
                />
                <span
                  data-testid={
                    datatestid ? `${datatestid}-overview-text` : null
                  }
                  className={`Body-1 ${!collapsed && 'padL10'} ${
                    isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                  }`}
                >
                  <VisibilitySensor
                    onChange={onVisibilityChangeTop}
                    offset={{ top: 70 }}
                    resizeCheck={true}
                  >
                    <div className="invisible-pixel" />
                  </VisibilitySensor>
                  {collapsed ? 'Profile' : 'My Profile'}
                </span>
              </div>
            </NavLink>
          </Menu.Item>
          {!collapsed && showPremiumBanner && (
            <Menu.ItemGroup
              key="premiumMenu"
              title={
                <GetPremiumBanner
                  getPremiumClass={getPremiumClass}
                  onVisibilityChangeBottom={onVisibilityChangeBottom}
                  bottomShadowOffset={bottomShadowOffset}
                  premiumReactivateCancelHook={premiumReactivateCancelHook}
                  seenPremiumReactivateCancelPixel={
                    seenPremiumReactivateCancelPixel
                  }
                  premiumReactivatePendingCancellationHook={
                    premiumReactivatePendingCancellationHook
                  }
                  seenPremiumReactivatePendingCancellationPixel={
                    seenPremiumReactivatePendingCancellationPixel
                  }
                  premiumPaymentPendingHook={premiumPaymentPendingHook}
                  isPreviouslyPremiumPlusFlow={isPreviouslyPremiumPlusFlow}
                  onClickReactivatePlus={onClickReactivatePlus}
                  isPlusUser={isPlusUser}
                  csTitle={csTitle}
                  {...props}
                />
              }
            />
          )}
          <Menu.Item
            key="creditManager"
            onClick={() => {
              creditManagerVisited?.checkAsVisited();
              onClick('Manager', FEATURE_NAME_CREDIT_MANAGER);
            }}
          >
            <NavLink
              className="format100"
              to="/creditManager"
              data-testid={
                datatestid ? `${datatestid}-credit-manager-link` : null
              }
              activeClassName="selected"
            >
              <div
                className={classnames(
                  'actionLink leftNavItemWrapper flex-1',
                  {},
                )}
              >
                <CreditManagerOutline
                  className={`menuButton outline ${
                    isPlusUser ? 'plusIcon' : ''
                  }`}
                  role=""
                  aria-label={'Credit Manager'}
                />
                <CreditManagerFilled
                  className="menuButton filled menuIconFilled"
                  role=""
                  aria-label={'Credit Manager'}
                />
                <span
                  data-testid={
                    datatestid ? `${datatestid}-credit-manager-text` : null
                  }
                  className={`Body-1 ${!collapsed && 'padL10'} ${
                    isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                  }`}
                >
                  Credit Manager
                </span>
                <ConditionalRendering
                  isTrue={!collapsed && !creditManagerVisited?.visited}
                >
                  <div className="redDotCircle width-5px height-5px pos-rel"></div>
                </ConditionalRendering>
                <ConditionalRendering
                  isTrue={collapsed && !creditManagerVisited?.visited}
                >
                  <div className="redDotCircle width-5px height-5px pos-rel collapsed"></div>
                </ConditionalRendering>
              </div>
            </NavLink>
            <HighlightFeatureLabel
              featureToHighlight={FEATURE_NAME_CREDIT_MANAGER}
            />
          </Menu.Item>
          <Menu.Item
            key="offers"
            onClick={() => {
              onClick(
                'Marketplace',
                FEATURE_NAME_MARKETPLACE,
                CREDIT_CARD_VERTICAL,
              );
            }}
          >
            <NavLink
              to="/offers"
              data-testid={datatestid ? `${datatestid}-marketplace-link` : null}
              activeClassName="selected"
              isActive={() => getOffersLinkActive(collapsed)}
            >
              <div className="leftNavItemWrapper">
                <MarketplaceButton
                  className={`menuButton outline ${
                    isPlusUser ? 'plusIcon' : ''
                  }`}
                  role=""
                  aria-label={'Offers'}
                />
                <MarketplaceButtonFilled
                  className="menuButton filled menuIconFilled"
                  role=""
                  aria-label={'Offers'}
                />
                <span
                  data-testid={
                    datatestid ? `${datatestid}-marketplace-text` : null
                  }
                  className={`Body-1 ${!collapsed && 'padL10'} ${
                    isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                  }`}
                >
                  Offers
                </span>
              </div>
            </NavLink>
            <HighlightFeatureLabel
              featureToHighlight={FEATURE_NAME_MARKETPLACE}
            />
          </Menu.Item>
          {!loading &&
            finalOfferTiles?.map(item => {
              if (!collapsed && !isPremiumUser) {
                return (
                  <Menu.Item
                    key={item.type}
                    onClick={() => {
                      onClick(
                        item.text,
                        item.featureToHighlight,
                        item.vertical,
                      );
                    }}
                    className="subMenuItem"
                  >
                    <SubNavLink
                      datatestid={
                        datatestid ? `${datatestid}-${item.datatestid}` : null
                      }
                      text={item.text}
                      navTo={item.url}
                      isActive={() => getOfferVerticalActive(item.type)}
                      highlightDot={
                        <HighlightFeatureLabel
                          featureToHighlight={item.featureToHighlight}
                        />
                      }
                    />
                  </Menu.Item>
                );
              } else {
                return null;
              }
            })}

          <Menu.Item
            key="cash"
            onClick={() => {
              onClick('cash', FEATURE_NAME_SESAMECASH, BANKING_VERTICAL);
            }}
          >
            <NavLink
              to="/cash"
              data-testid={datatestid ? `${datatestid}-profile-link` : null}
              activeClassName="selected"
            >
              <div className="leftNavItemWrapper">
                <SesameCashIconOutline
                  className={`menuButton outline ${
                    isPlusUser ? 'plusIcon' : ''
                  }`}
                  role=""
                  aria-label="Sesame Cash"
                />
                <SesameCashIconFilled
                  className="menuButton filled menuIconFilled"
                  role=""
                  aria-label="Sesame Cash"
                />
                <span
                  data-testid={datatestid ? `${datatestid}-profile-text` : null}
                  className={`sesameCashMenuText Body-1 ${
                    !collapsed && 'padL10'
                  } ${isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''}`}
                >
                  Sesame Cash
                </span>
              </div>
            </NavLink>
            <HighlightFeatureLabel
              featureToHighlight={FEATURE_NAME_SESAMECASH}
            />
          </Menu.Item>
          {!isPremiumUser && (
            <Menu.Item
              key="alerts"
              onClick={() => {
                onClick('Alerts', FEATURE_NAME_ALERT_MONITORING);
              }}
            >
              <NavLink
                to="/alerts/all"
                data-testid={datatestid ? `${datatestid}-alerts-link` : null}
                activeClassName="selected"
                className="pos-rel"
                isActive={() => checkDuplicateTabActiveStatus('/alerts/all')}
              >
                <div className="leftNavItemWrapper">
                  <AlertsOutline
                    className={`menuButton outline ${
                      isPlusUser ? 'plusIcon' : ''
                    }`}
                    role=""
                    aria-label="Notifications"
                  />
                  <AlertsFilled
                    className="menuButton filled menuIconFilled"
                    role=""
                    aria-label="Notifications"
                  />
                  <span
                    data-testid={
                      datatestid ? `${datatestid}-alerts-text` : null
                    }
                    className={`Body-1 ${!collapsed && 'padL10'} ${
                      isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                    }`}
                  >
                    Notifications
                  </span>
                </div>
                {unreadAlertCount > 0 && (
                  <Badge type="numbered" className="pos-abs margL8">
                    {unreadAlertCount}
                  </Badge>
                )}
              </NavLink>
              <HighlightFeatureLabel
                featureToHighlight={FEATURE_NAME_ALERT_MONITORING}
              />
            </Menu.Item>
          )}

          {!isPremiumUser && (
            <Menu.Item
              key="premiumCreditReports"
              onClick={() => {
                onClick(
                  'Credit Report',
                  FEATURE_NAME_CREDIT_REPORT,
                  SUBSCRIPTION_VERTICAL,
                );
              }}
            >
              <NavLink
                to="/creditReports/tabs"
                data-testid={
                  datatestid ? `${datatestid}-credit-reports-link` : null
                }
                activeClassName="selected"
                className="pos-rel"
                isActive={() =>
                  checkDuplicateTabActiveStatus(
                    '/creditReports/tabs',
                    'premiumCreditReports',
                    true,
                  )
                }
              >
                <div className="leftNavItemWrapper">
                  <CreditReportOutline
                    className={`menuButton outline ${
                      isPlusUser ? 'plusIcon' : ''
                    }`}
                    role=""
                    aria-label="Credit Report"
                  />
                  <CreditReportFilled
                    className="menuButton filled menuIconFilled"
                    role=""
                    aria-label="Credit Report"
                  />
                  <span
                    data-testid={
                      datatestid ? `${datatestid}-alerts-text` : null
                    }
                    className={`Body-1 ${!collapsed && 'padL10'} ${
                      isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                    }`}
                  >
                    Credit Report
                  </span>
                </div>
              </NavLink>
              <HighlightFeatureLabel
                featureToHighlight={FEATURE_NAME_CREDIT_REPORT}
              />
            </Menu.Item>
          )}

          {!isPremiumUser && (
            <Menu.Item
              key="profile"
              onClick={() => {
                onClick('Profile', FEATURE_NAME_PROFILE);
              }}
            >
              <NavLink
                to="/profile"
                data-testid={datatestid ? `${datatestid}-profile-link` : null}
                activeClassName="selected"
                isActive={() => checkDuplicateTabActiveStatus('/profile')}
              >
                <div className="leftNavItemWrapper">
                  <ProfileButton
                    className={`menuButton outline ${
                      isPlusUser ? 'plusIcon' : ''
                    }`}
                    role=""
                    aria-label="Settings"
                  />
                  <ProfileButtonFilled
                    className="menuButton filled menuIconFilled"
                    role=""
                    aria-label="Settings"
                  />
                  <span
                    data-testid={
                      datatestid ? `${datatestid}-profile-text` : null
                    }
                    className={`Body-1 ${!collapsed && 'padL10'} ${
                      isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                    }`}
                  >
                    Settings
                  </span>
                </div>
              </NavLink>
              {!collapsed && (
                <HighlightFeatureLabel
                  featureToHighlight={FEATURE_NAME_PROFILE}
                />
              )}
            </Menu.Item>
          )}
          <Menu.ItemGroup
            key="premiumMenu"
            title={
              <GetPremiumLabel
                getPremiumClass={getPremiumClass}
                onVisibilityChangeBottom={onVisibilityChangeBottom}
                bottomShadowOffset={bottomShadowOffset}
                isPlusUser={isPlusUser}
                csTitle={csTitle}
                {...props}
              />
            }
          >
            {!collapsed &&
              !isPremiumUser &&
              getDisplayPremiumFeature('premiumCreditReports') && (
                <Menu.Item
                  key="premiumCreditReports"
                  onClick={() => {
                    onClick('Full Credit Report', null, SUBSCRIPTION_VERTICAL);
                  }}
                  className={getPremiumClass('premiumCreditReports')}
                >
                  <NavLink
                    to={getPremiumLink(
                      '/creditReports/tabs',
                      'premiumCreditReports',
                      PREMIUM_FEATURE_CREDIT_REPORT,
                    )}
                    data-testid={
                      datatestid
                        ? `${datatestid}-premiumCreditReports-link`
                        : null
                    }
                    activeClassName="selected"
                    isActive={() =>
                      checkDuplicateTabActiveStatus(
                        '/creditReports/tabs',
                        'premiumCreditReports',
                        true,
                      )
                    }
                  >
                    <FullCreditReport
                      className={`menuButton outline ${
                        isPlusUser ? 'plusIcon' : ''
                      }`}
                      role=""
                      aria-label="Full Credit Reports"
                    />
                    <FullCreditReportFilled
                      className="menuButton filled menuIconFilled"
                      role=""
                      aria-label="Full Credit Reports"
                    />
                    <span
                      data-testid={
                        datatestid
                          ? `${datatestid}-premiumCreditReports-text`
                          : null
                      }
                      className={`Body-1 ${!collapsed && 'padL10'} ${
                        isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                      }`}
                    >
                      Full Credit Reports
                    </span>
                  </NavLink>
                </Menu.Item>
              )}

            {!isPremiumUser && (
              <Menu.Item
                key="plusCreditLock"
                onClick={() => {
                  onClick(
                    'Credit Lock',
                    FEATURE_NAME_CREDIT_LOCK,
                    SUBSCRIPTION_VERTICAL,
                  );
                }}
                className={getPremiumClass('premiumCreditLock')}
              >
                <NavLink
                  to={getPremiumLink(
                    '/creditReports/tabs',
                    'premiumCreditLock',
                  )}
                  data-testid={
                    datatestid ? `${datatestid}-credit-lock-link` : null
                  }
                  activeClassName="selected"
                  className="pos-rel"
                  isActive={() =>
                    checkDuplicateTabActiveStatus(
                      '/creditReports/tabs',
                      'premiumCreditLock',
                      true,
                    )
                  }
                >
                  <div className="leftNavItemWrapper">
                    <CreditLockOutline
                      className={`menuButton outline ${
                        isPlusUser ? 'plusIcon' : ''
                      }`}
                      role=""
                      aria-label="Credit Lock"
                    />
                    <CreditLockFilled
                      className="menuButton filled menuIconFilled"
                      role=""
                      aria-label="Credit Lock"
                    />
                    <span
                      data-testid={
                        datatestid ? `${datatestid}-alerts-text` : null
                      }
                      className={`Body-1 ${!collapsed && 'padL10'} ${
                        isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                      }`}
                    >
                      Credit Lock
                    </span>
                  </div>
                </NavLink>
                <HighlightFeatureLabel
                  featureToHighlight={FEATURE_NAME_CREDIT_LOCK}
                />
              </Menu.Item>
            )}

            {isRentReportingNav &&
              getDisplayPremiumFeature('rentReporting') && (
                <Menu.Item
                  key="rentPaymentReporting"
                  onClick={() => {
                    redirectToRentReporting();
                  }}
                  className={getPremiumClass('rentReporting')}
                >
                  <RentReportingMenuItem
                    outlineIcon={RentReportingIcon}
                    filledIcon={RentReportingIcon}
                    datatestid={datatestid}
                    isPlusUser={isPlusUser}
                    isActive={checkDuplicateTabActiveStatus(
                      '/rentPaymentReporting',
                    )}
                    navLinkText="Rent Reporting"
                    premiumClass={getPremiumClass('rentReporting')}
                    collapsed={collapsed}
                  />
                </Menu.Item>
              )}

            {!collapsed &&
              getDisplayPremiumFeature('premiumAdvancedMonitoring') && (
                <Menu.Item
                  key="premiumAdvancedMonitoring"
                  onClick={() => {
                    onClick('Advanced Monitoring');
                  }}
                  className={getPremiumClass('premiumAdvancedMonitoring')}
                >
                  <NavLink
                    to={getPremiumLink(
                      '/alerts/creditMonitoring',
                      'premiumAdvancedMonitoring',
                      PREMIUM_FEATURE_ADVANCED_MONITORING,
                    )}
                    data-testid={
                      datatestid
                        ? `${datatestid}-premiumAdvancedMonitoring-link`
                        : null
                    }
                    activeClassName="selected"
                    isActive={() =>
                      checkDuplicateTabActiveStatus(
                        '/alerts/creditMonitoring',
                        'premiumAdvancedMonitoring',
                        true,
                      )
                    }
                  >
                    <AdvancedMonitoring
                      className={`menuButton outline ${
                        isPlusUser ? 'plusIcon' : ''
                      }`}
                      role=""
                      aria-label="Advanced Monitoring"
                    />
                    <AdvancedMonitoringFilled
                      className="menuButton filled menuIconFilled"
                      role=""
                      aria-label="Advanced Monitoring"
                    />
                    <span
                      data-testid={
                        datatestid
                          ? `${datatestid}-premiumAdvancedMonitoring-text`
                          : null
                      }
                      className={`Body-1 ${!collapsed && 'padL10'} ${
                        isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                      }`}
                    >
                      Advanced Monitoring
                    </span>
                  </NavLink>
                </Menu.Item>
              )}
            {isPremiumUser && (
              <Menu.Item
                key="premiumCreditReports"
                onClick={() => {
                  onClick(
                    'Credit Report',
                    FEATURE_NAME_CREDIT_REPORT,
                    SUBSCRIPTION_VERTICAL,
                  );
                }}
              >
                <NavLink
                  to={getPremiumLink(
                    '/creditReports/tabs',
                    'premiumCreditReports',
                  )}
                  data-testid={
                    datatestid ? `${datatestid}-credit-reports-link` : null
                  }
                  activeClassName="selected"
                  className="pos-rel"
                  isActive={() =>
                    checkDuplicateTabActiveStatus(
                      '/creditReports/tabs',
                      'premiumCreditReports',
                      true,
                    )
                  }
                >
                  <div className="leftNavItemWrapper">
                    <CreditReportOutline
                      className={`menuButton outline ${
                        isPlusUser ? 'plusIcon' : ''
                      }`}
                      role=""
                      aria-label="Credit Report"
                    />
                    <CreditReportFilled
                      className="menuButton filled menuIconFilled"
                      role=""
                      aria-label="Credit Report"
                    />
                    <span
                      data-testid={
                        datatestid ? `${datatestid}-alerts-text` : null
                      }
                      className={`Body-1 ${!collapsed && 'padL10'} ${
                        isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                      }`}
                    >
                      Credit Report
                    </span>
                  </div>
                </NavLink>
                <HighlightFeatureLabel
                  featureToHighlight={FEATURE_NAME_CREDIT_REPORT}
                />
              </Menu.Item>
            )}

            {creditLockEnabled && (
              <Menu.Item
                key="plusCreditLock"
                onClick={() => {
                  onClick(
                    'Credit Lock',
                    FEATURE_NAME_CREDIT_LOCK,
                    SUBSCRIPTION_VERTICAL,
                  );
                }}
              >
                <NavLink
                  to={getPremiumLink(
                    '/creditReports/tabs',
                    'premiumCreditLock',
                  )}
                  data-testid={
                    datatestid ? `${datatestid}-credit-lock-link` : null
                  }
                  activeClassName="selected"
                  className="pos-rel"
                  isActive={() =>
                    checkDuplicateTabActiveStatus(
                      '/creditReports/tabs',
                      'premiumCreditLock',
                      true,
                    )
                  }
                >
                  <div className="leftNavItemWrapper">
                    <CreditLockOutline
                      className={`menuButton outline ${
                        isPlusUser ? 'plusIcon' : ''
                      }`}
                      role=""
                      aria-label="Credit Lock"
                    />
                    <CreditLockFilled
                      className="menuButton filled menuIconFilled"
                      role=""
                      aria-label="Credit Lock"
                    />
                    <span
                      data-testid={
                        datatestid ? `${datatestid}-alerts-text` : null
                      }
                      className={`Body-1 ${!collapsed && 'padL10'} ${
                        isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                      }`}
                    >
                      Credit Lock
                    </span>
                  </div>
                </NavLink>
                <HighlightFeatureLabel
                  featureToHighlight={FEATURE_NAME_CREDIT_LOCK}
                />
              </Menu.Item>
            )}
            {isPremiumUser && (
              <Menu.Item
                key="alerts"
                onClick={() => {
                  onClick('Alerts', FEATURE_NAME_ALERT_MONITORING);
                }}
              >
                <NavLink
                  to="/alerts/all"
                  data-testid={datatestid ? `${datatestid}-alerts-link` : null}
                  activeClassName="selected"
                  className="pos-rel"
                  isActive={() => checkDuplicateTabActiveStatus('/alerts/all')}
                >
                  <div className="leftNavItemWrapper">
                    <AlertsOutline
                      className={`menuButton outline ${
                        isPlusUser ? 'plusIcon' : ''
                      }`}
                      role=""
                      aria-label="Notifications"
                    />
                    <AlertsFilled
                      className="menuButton filled menuIconFilled"
                      role=""
                      aria-label="Notifications"
                    />
                    <span
                      data-testid={
                        datatestid ? `${datatestid}-alerts-text` : null
                      }
                      className={`Body-1 ${!collapsed && 'padL10'} ${
                        isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                      }`}
                    >
                      Notifications
                    </span>
                  </div>
                  {unreadAlertCount > 0 && (
                    <Badge type="numbered" className="pos-abs margL8">
                      {unreadAlertCount}
                    </Badge>
                  )}
                </NavLink>
                <HighlightFeatureLabel
                  featureToHighlight={FEATURE_NAME_ALERT_MONITORING}
                />
              </Menu.Item>
            )}
            {isPremiumUser && addIDProtectionMenuItem && (
              <Menu.Item
                key="premiumIDProtection"
                onClick={() => {
                  onClick(
                    'ID Protection',
                    FEATURE_NAME_ID_PROTECTION,
                    SUBSCRIPTION_VERTICAL,
                  );
                }}
              >
                <NavLink
                  to="/idProtection?nav=premiumIDProtection"
                  data-testid={
                    datatestid ? `${datatestid}-id-protection-link` : null
                  }
                  activeClassName="selected"
                  className="pos-rel"
                  isActive={() =>
                    checkDuplicateTabActiveStatus(
                      '/idProtection',
                      'premiumIDProtection',
                      true,
                    )
                  }
                >
                  <div className="leftNavItemWrapper">
                    <IDProtectionOutline
                      className={`menuButton outline ${
                        isPlusUser ? 'plusIcon' : ''
                      }`}
                      role=""
                      aria-label="ID Protection"
                    />
                    <IDProtectionFilled
                      className="menuButton filled menuIconFilled"
                      role=""
                      aria-label="ID Protection"
                    />
                    <span
                      data-testid={
                        datatestid ? `${datatestid}-alerts-text` : null
                      }
                      className={`Body-1 ${!collapsed && 'padL10'} ${
                        isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                      }`}
                    >
                      ID Protection
                    </span>
                  </div>
                </NavLink>
                <HighlightFeatureLabel
                  featureToHighlight={FEATURE_NAME_ID_PROTECTION}
                />
              </Menu.Item>
            )}
            {!collapsed && getDisplayPremiumFeature('premiumLiveSupport') && (
              <Menu.Item
                key="premiumLiveSupport"
                onClick={() => {
                  onClick('Live Experts', null, SUBSCRIPTION_VERTICAL);
                }}
                className={getPremiumClass('premiumLiveSupport')}
              >
                <NavLink
                  to={getPremiumLink(
                    '/liveexperts',
                    'premiumLiveSupport',
                    PREMIUM_FEATURE_LIVE_EXPERTS,
                  )}
                  data-testid={
                    datatestid ? `${datatestid}-premiumLiveSupport-link` : null
                  }
                  activeClassName="selected"
                  isActive={() =>
                    checkDuplicateTabActiveStatus(
                      '/liveexperts',
                      'premiumLiveSupport',
                      true,
                    )
                  }
                >
                  <LiveSupport
                    className={`menuButton outline ${
                      isPlusUser ? 'plusIcon' : ''
                    }`}
                    role=""
                    aria-label="Live Experts"
                  />
                  <LiveSupportFilled
                    className="menuButton filled menuIconFilled"
                    role=""
                    aria-label="Live Experts"
                  />

                  <span
                    data-testid={
                      datatestid
                        ? `${datatestid}-premiumLiveSupport-text`
                        : null
                    }
                    className={`Body-1 ${!collapsed && 'padL10'} ${
                      isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                    }`}
                  >
                    Live Experts
                  </span>
                </NavLink>
              </Menu.Item>
            )}

            {!collapsed &&
              getDisplayPremiumFeature(
                PREMIUM_FEATURE_KEY_ID_THEFT_PROTECTION,
              ) && (
                <Menu.Item
                  key="premiumIdTheftProtection"
                  onClick={() => {
                    onClick(
                      '$1M Identify Theft Insurance',
                      null,
                      SUBSCRIPTION_VERTICAL,
                    );
                  }}
                  className={getPremiumClass('premiumIdTheftProtection')}
                >
                  <NavLink
                    to={getPremiumLink(
                      '/liveExperts',
                      'premiumIdTheftProtection',
                      PREMIUM_FEATURE_ID_THEFT_PROTECTION,
                      '&tab=identityTheftInsurance',
                    )}
                    data-testid={
                      datatestid
                        ? `${datatestid}-premiumIdTheftProtection-link`
                        : null
                    }
                    activeClassName="selected"
                    isActive={() =>
                      checkDuplicateTabActiveStatus(
                        '/liveExperts',
                        'premiumIdTheftProtection',
                        true,
                      )
                    }
                  >
                    <Icon
                      className={`menuButton idTheftInsurance outline ${
                        isPlusUser ? 'plusIconWhite' : ''
                      }`}
                      component={IDTheftInsurance}
                      role=""
                      aria-label="$1M ID Theft Insurance"
                    />
                    <span
                      data-testid={
                        datatestid
                          ? `${datatestid}-premiumIdTheftProtection-text`
                          : null
                      }
                      className={`Body-1 ${
                        isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                      }`}
                    >
                      $1M ID Theft Insurance
                    </span>
                  </NavLink>
                </Menu.Item>
              )}

            {!collapsed &&
              getDisplayPremiumFeature(PREMIUM_FEATURE_KEY_WALLET) && (
                <Menu.Item
                  key="premiumWalletProtection"
                  onClick={() => {
                    onClick('Wallet Protection');
                  }}
                  className={getPremiumClass('premiumWalletProtection')}
                >
                  <NavLink
                    to={getPremiumLink(
                      '/liveExperts',
                      'premiumWalletProtection',
                      PREMIUM_FEATURE_WALLET_PROTECTION,
                      '&tab=stolenLostWalletProtection',
                    )}
                    data-testid={
                      datatestid
                        ? `${datatestid}-premiumWalletProtection-link`
                        : null
                    }
                    activeClassName="selected"
                    isActive={() =>
                      checkDuplicateTabActiveStatus(
                        'liveExperts',
                        'premiumWalletProtection',
                        true,
                      )
                    }
                  >
                    <WalletProtection
                      className={`menuButton outline ${
                        isPlusUser ? 'plusIcon' : ''
                      }`}
                      role=""
                      aria-label="Wallet Protection"
                    />
                    <WalletProtectionFilled
                      className="menuButton filled menuIconFilled"
                      role=""
                      aria-label="Wallet Protection"
                    />
                    <span
                      data-testid={
                        datatestid
                          ? `${datatestid}-premiumWalletProtection-text`
                          : null
                      }
                      className={`Body-1 ${!collapsed && 'padL10'} ${
                        isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                      }`}
                    >
                      Wallet Protection
                    </span>
                  </NavLink>
                </Menu.Item>
              )}
            {!collapsed && isPremiumPlatinumUser && (
              <Menu.Item
                key="premiumIdMonitoring"
                onClick={() => {
                  onClick('ID Monitoring');
                }}
                className={getPremiumClass('premiumIdMonitoring')}
              >
                <NavLink
                  to={getPremiumLink(
                    '/alerts/idProtection',
                    'premiumIdMonitoring',
                    PREMIUM_FEATURE_ID_MONITORING,
                  )}
                  data-testid={
                    datatestid ? `${datatestid}-premiumIdMonitoring-link` : null
                  }
                  activeClassName="selected"
                  isActive={() =>
                    checkDuplicateTabActiveStatus(
                      '/alerts/idProtection',
                      'premiumIdMonitoring',
                      true,
                    )
                  }
                >
                  <IdMonitoring
                    className={`menuButton outline ${
                      isPlusUser ? 'plusIcon' : ''
                    }`}
                    role=""
                    aria-label="ID Monitoring"
                  />
                  <IdMonitoringFilled
                    className="menuButton filled menuIconFilled"
                    role=""
                    aria-label="ID Monitoring"
                  />
                  <span
                    data-testid={
                      datatestid
                        ? `${datatestid}-premiumIdMonitoring-text`
                        : null
                    }
                    className={`Body-1 ${!collapsed && 'padL10'} ${
                      isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                    }`}
                  >
                    <VisibilitySensor
                      onChange={onVisibilityChangeBottom}
                      offset={{ bottom: bottomShadowOffset }}
                      resizeCheck={true}
                    >
                      <div className="invisible-pixel" />
                    </VisibilitySensor>
                    ID Monitoring
                  </span>
                </NavLink>
              </Menu.Item>
            )}
            {isPremiumUser && (
              <Menu.Item
                key="profile"
                onClick={() => {
                  onClick('Profile', FEATURE_NAME_PROFILE);
                }}
              >
                <NavLink
                  to="/profile"
                  data-testid={datatestid ? `${datatestid}-profile-link` : null}
                  activeClassName="selected"
                  isActive={() => checkDuplicateTabActiveStatus('/profile')}
                >
                  <div className="leftNavItemWrapper">
                    <ProfileButton
                      className={`menuButton outline ${
                        isPlusUser ? 'plusIcon' : ''
                      }`}
                      role=""
                      aria-label="Settings"
                    />
                    <ProfileButtonFilled
                      className="menuButton filled menuIconFilled"
                      role=""
                      aria-label="Settings"
                    />
                    <span
                      data-testid={
                        datatestid ? `${datatestid}-profile-text` : null
                      }
                      className={`Body-1 ${!collapsed && 'padL10'} ${
                        isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                      }`}
                    >
                      Settings
                    </span>
                  </div>
                </NavLink>
                {!collapsed && (
                  <HighlightFeatureLabel
                    featureToHighlight={FEATURE_NAME_PROFILE}
                  />
                )}
              </Menu.Item>
            )}
          </Menu.ItemGroup>
        </Menu>
        <Menu
          className={`${bottomMenuClass} ${bottomShadowClass} ${plusBottomClass} margB8 m-margB0 m-padB8`}
          mode="inline"
          theme="lite"
          inlineCollapsed={collapsed}
        >
          {!isOcfRole ? (
            <Menu.Item
              key="1"
              onClick={() => {
                onClick('Logout');
              }}
            >
              <FlexContainer>
                <NavLink
                  to="/logout?reason=MANUAL"
                  data-testid={datatestid ? `${datatestid}-logout-link` : null}
                  activeClassName="selected"
                >
                  <div className="leftNavItemWrapper">
                    <SignOut
                      className={`menuButton outline ${
                        isPlusUser ? 'plusIcon' : ''
                      }`}
                      role=""
                      aria-label="Sign Out"
                    />
                    <SignOut
                      className="menuButton filled menuIconFilled"
                      role=""
                      aria-label="Sign Out"
                    />
                    <span
                      data-testid={
                        datatestid ? `${datatestid}-logout-text` : null
                      }
                      className={`Body-1 ${!collapsed && 'padL10'} ${
                        isPlusNavLabelClass ? ` ${isPlusNavLabelClass}` : ''
                      }`}
                    >
                      Sign Out
                    </span>
                  </div>
                </NavLink>
                <span className="padr6"></span>
              </FlexContainer>
            </Menu.Item>
          ) : (
            <div className="pad8"></div>
          )}
          <div>
            <span
              className="policyList"
              data-testid={datatestid ? `${datatestid}-policy-text` : null}
            >
              <a
                className="otherLink inlineBlock"
                href={privacyPolicyUrl}
                target="_blank"
              >
                <Label
                  onClick={() => {
                    disclosureItemClicked('Privacy Policy');
                  }}
                  type="Body-2"
                  color={isPlusUser ? 'lBlue085' : 'fontBlue100'}
                  className="otherLink"
                >
                  Privacy Policy
                </Label>
              </a>
            </span>
            <span
              className="policyList"
              data-testid={datatestid ? `${datatestid}-policy-text` : null}
            >
              <a
                className="otherLink inlineBlock"
                href={termsOfUseUrl}
                target="_blank"
              >
                <Label
                  onClick={() => {
                    disclosureItemClicked('Terms of Use');
                  }}
                  type="Body-2"
                  color={isPlusUser ? 'lBlue085' : 'fontBlue100'}
                  className="otherLink"
                >
                  &nbsp;&nbsp; - &nbsp;&nbsp;Terms of Use
                </Label>
              </a>
            </span>
          </div>
        </Menu>
      </div>
    );
  }
};

export default MenuList;
/* eslint-disable-next-line react-hooks/rules-of-hooks */
