import {
  CREDIT_CARD_OFFER,
  SEE_ALL_CARDS,
} from 'partial/OfferManager/offer.constants';
import { getCStrack } from './localstorage';
import { stateList } from 'helpers/address.helper';
import { formatIncome } from './money.helper';
import { formatApr } from './number';
import { getDecisionForFlag } from 'externals/_tracking/optimizely/optFlags';
import { cloneDataObject } from 'helpers/objectHelpers';

export const DEFAULT_TC_TEXT = 'See Rates and Fees';

export const hasOfferIdInOffers = (offers, offerId) => {
  return offers?.some(element => {
    if (element.offerType === 'PERSONAL_LOAN') {
      return element.personalLoanId === offerId;
    }
    return element.creditCardId === offerId;
  });
};

export const getMostHelpfulPositiveReview = function (reviews) {
  let mostPositive = null;
  reviews.forEach((item, index) => {
    if (!mostPositive) {
      mostPositive = item;
    } else {
      if (
        item.rating > mostPositive.rating &&
        item.posVote > mostPositive.posVote
      ) {
        mostPositive = item;
      }
    }
  });
  return mostPositive;
};

export const getMostHelpfulNegativeReview = function (reviews, mostHelpfulPos) {
  let mostNegative = null;
  reviews.forEach((item, index) => {
    if (!mostNegative) {
      mostNegative = item;
    } else {
      if (
        item.rating < mostNegative.rating &&
        item.posVote > mostNegative.posVote &&
        item !== mostHelpfulPos
      ) {
        mostNegative = item;
      }
    }
  });
  return mostNegative;
};

export const assembleReviewList = function (reviewList, reviewPageType) {
  let assembledList = [];
  if (reviewPageType) {
    return reviewList;
  } else {
    let mostHelpfulPos = getMostHelpfulPositiveReview(reviewList);
    let mostHelpfulNeg = getMostHelpfulNegativeReview(
      reviewList,
      mostHelpfulPos,
    );
    assembledList.push(mostHelpfulPos);
    assembledList.push(mostHelpfulNeg);
    return assembledList;
  }
};

export const getUserInfoForApplyLinkPostCreditOne = userInfo => {
  let userInfoAttributes = [];
  let addressObj = userInfo.primaryAddress ? userInfo.primaryAddress : {};
  userInfoAttributes.push({ fname: userInfo.firstName });
  userInfoAttributes.push({ mname: userInfo.middleInitial });
  userInfoAttributes.push({ lname: userInfo.lastName });
  userInfoAttributes.push({ address1: addressObj.street });
  userInfoAttributes.push({ apartment: addressObj.unit });
  userInfoAttributes.push({ city: addressObj.city });
  userInfoAttributes.push({ state: stateList[addressObj.state]['abbrev'] });
  userInfoAttributes.push({ zipcode: addressObj.zip });
  userInfoAttributes.push({ mobilePhone: userInfo.contactPhoneNumber });
  return userInfoAttributes;
};

export const filterCCOffersAO = offerMetadataList => {
  if (offerMetadataList.length > 0) {
    const lastCardType =
      offerMetadataList[offerMetadataList.length - 1]['type'];
    const validLengthForFilter = lastCardType === SEE_ALL_CARDS ? 2 : 1;
    if (offerMetadataList.length >= validLengthForFilter) {
      return offerMetadataList.filter(offer => {
        return (
          offer.offerType !== CREDIT_CARD_OFFER || offer.approvalOdds !== 'POOR'
        );
      });
    }
  }
  return offerMetadataList;
};

export const showLoanTerms = (apr, termMonths) => {
  let showTermsSection = false;
  if (apr && apr > 0 && termMonths && termMonths > 0) {
    showTermsSection = true;
  }
  return showTermsSection;
};

export const getCardModule = function (offerResponse, moduleName) {
  let modules = offerResponse?.modules;
  for (var i = 0; i < modules?.length; i++) {
    if (modules[i]['module'] === moduleName) {
      return cloneDataObject(modules[i]);
    }
  }
  return null;
};

export const getQuinStreetTrnId = userInfo => {
  if (userInfo) {
    return `${userInfo.userId}:${getCStrack()}`;
  } else {
    return null;
  }
};

export const getOfferRankingType = (
  creditProfile,
  noDefault = false,
  isCCMarketplace = false,
  isOcf = false,
) => {
  const modifyCardsRequestParametersExp = getDecisionForFlag(
    'modify_cards_request_parameters',
    {
      defaultConfig: {
        rankingType: 'CAPI_1_0_RPC_0_0_CS',
      },
    },
  );

  let rankType = modifyCardsRequestParametersExp?.config.rankingType;

  if ((isCCMarketplace && rankType) || isOcf) {
    rankType = `-${rankType}`;
  }
  return rankType;
};

export const getOffersRequestParameters = (
  isCCMarketplace = false,
  isOcf = false,
) => {
  const modifyOffersRequestParametersExp = getDecisionForFlag(
    'modify_cards_request_parameters',
    {
      defaultConfig: {
        rankingType: 'DS_CC_RANK',
        showPercent: false,
        preApprovalBadgeOverride: false,
        replaceBadgeByGuaranteed: false,
        cciVersion: 'V3',
      },
    },
  );

  const getAllOfferExp = getDecisionForFlag('PlusAllOffers_Web');

  let guaranteeBadge =
    modifyOffersRequestParametersExp?.config?.replaceBadgeByGuaranteed;
  let rankType = modifyOffersRequestParametersExp?.config?.rankingType;
  let showPercent = modifyOffersRequestParametersExp?.config?.showPercent;
  let cciVersion = modifyOffersRequestParametersExp?.config?.cciVersion;
  let includeSuggestedCardForPlusUser =
    getAllOfferExp?.config?.PlusAllOffers ?? false;

  let preApprovalBadgeOverride =
    modifyOffersRequestParametersExp?.config?.preApprovalBadgeOverride;

  if ((isCCMarketplace && rankType) || isOcf) {
    rankType = `-${rankType}`;
  }

  showPercent = showPercent > 0 ? showPercent : false;
  const parameters = {};
  if (showPercent) {
    parameters.showPercent = showPercent;
  }
  if (cciVersion) {
    parameters.cciVersion = cciVersion;
  }
  parameters.preApprovalBadgeOverride = preApprovalBadgeOverride;
  parameters.rankingType = rankType;
  parameters.guaranteeBadge = guaranteeBadge;
  parameters.includeSuggestedCardForPlusUser = includeSuggestedCardForPlusUser;

  return parameters;
};

export const getPublishedReviewList = reviewList =>
  reviewList.filter(item => {
    return item.status === 'PUBLISHED';
  });

export const autoRefinanceOfferHowIsCalculatedTooltip = (
  overviewAutoLoanHowIsThisCalculated,
  loanDetail,
) => {
  let autoLoanSectionTooltip = '';
  if (overviewAutoLoanHowIsThisCalculated) {
    autoLoanSectionTooltip = overviewAutoLoanHowIsThisCalculated;
    autoLoanSectionTooltip = autoLoanSectionTooltip
      .replace('{monthlyPayment}', formatIncome(loanDetail?.monthlyPayment))
      .replace('{apr}', `${formatApr(loanDetail?.apr)}%`)
      .replace('{term}', loanDetail?.term);
  }

  return autoLoanSectionTooltip;
};

export const hasPrequalPersonalLoanOffersInContainer = container => {
  return container?.modules?.find(
    m =>
      m.module === 'PL_PREQUAL_OFFER_SUMMARY_MODULE' &&
      m.metadata?.uiModule === 'PL_PREQUAL_OFFER_MODULE' &&
      m.preApprovalStatus === 'SUCCESS',
  );
};

export const getHighestLimitHelper = offers => {
  let highestLimit = 0;
  const numOffers = offers.length > 3 ? 3 : offers.length;
  for (let n = 0; n < numOffers; n++) {
    let offer = offers[n];
    if (offer.predictedCreditLimit > highestLimit && !offer.hideCreditLimit) {
      highestLimit = offer.predictedCreditLimit;
    }
  }
  return highestLimit;
};

export const replaceCardsCtaByProductKey = (
  overwriteByFlagwExclusionList,
  ctaActionTitle,
  ctaByFlag,
  ctaOCFByFlag,
) => {
  if (overwriteByFlagwExclusionList) {
    return overwriteByFlagwExclusionList;
  } else if (ctaByFlag) {
    return ctaByFlag;
  } else if (ctaOCFByFlag) {
    return ctaOCFByFlag;
  } else {
    return ctaActionTitle;
  }
};
