import {
  apiUrls,
  buildSessionEndpoint,
  buildOcfSessionEndpoint,
  buildUserSessionStatus,
  buildChatSessionEndpoint,
} from 'api-urls';
import {
  buildFetchOptionsWithHeaders,
  standardizedFetch,
  standardizedFetchHandler,
} from './service_helpers';
import {
  clearCStrack,
  clearRefCode,
  clearUsrTkn,
  clearResurrectedUser,
  removeItem,
  clearSnackBar,
  clearHideFreeTrialPremiumBanner,
  clearAcknowledgePrescreen,
} from 'helpers/localstorage';
import { getSlapiApiKey } from 'helpers/env';
import { clearCSContext } from 'externals/_tracking/optimizely/optimizely.track';
import { ssnExistsData } from 'pages/SignupPage/types/signup.constants';
import {
  clearMarketingRefCode,
  clearMarketingCookieAttr,
} from 'helpers/marketing.helper';
import { deleteCookieValue, getCookieValue } from 'helpers/cookie.helper';
import { getIovationBlackbox } from 'helpers/common';

export async function postSession(
  email,
  password,
  pageName,
  sessionIdentifier,
  jwt2FAToken,
  isPreScreen,
  rememberMe = false,
) {
  const payload = { apiKey: getSlapiApiKey() };
  const customHeaders = {};
  const trk = getCookieValue('trk');
  const blackbox = getIovationBlackbox();
  if (trk) {
    Object.assign(customHeaders, { 'X-affiliate-tracking-id': trk });
  }
  if (jwt2FAToken) {
    Object.assign(customHeaders, { 'X-cs-2fa': jwt2FAToken });
  }

  if (email && password) {
    Object.assign(payload, { email: email, password: password });
  }

  if (pageName) {
    Object.assign(payload, { page: pageName });
  }
  if (sessionIdentifier) {
    //if session tracking already exists, continue using same tracking id
    Object.assign(payload, { sessionIdentifier });
  }

  Object.assign(payload, { blackbox, rememberMe });

  const body = JSON.stringify(payload);
  const fetchOpts = buildFetchOptionsWithHeaders(
    { method: 'POST', body },
    customHeaders,
  );

  return standardizedFetchHandler(buildSessionEndpoint(isPreScreen), fetchOpts);
}

function handleLogoutResponse(response) {
  if (response.status === 401 || response.status === 403) {
    throw new Error('Unauthorized');
  } else {
    return response.text().then(text => {});
  }
}

export const clearSesameCashCookies = () => {
  deleteCookieValue('showSesameCashEnroll');
};

const cleanupSignupItems = () => {
  removeItem(ssnExistsData);
  removeItem('otpMethod');
  removeItem('maxOtpAttemptsReached');
  removeItem('passwordStep');
  removeItem('refLp');
  removeItem('dupeSSNsignup');
  removeItem('signupRedirects');
  removeItem('isTestAccount');
  removeItem('isEncryptedEmail');
  removeItem('fromPrefilledSignup');
  removeItem('oneClickSignupTracking');
  removeItem('dupKBAPhoneVerification');
  removeItem('isPublicPremiumLP');
  removeItem('isDMSignup');
  removeItem('ocsTempPwd');
  clearMarketingCookieAttr('PromoCode');
  clearHideFreeTrialPremiumBanner();
};

export async function postLogout() {
  // No matter what remove these items to logout user.
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('userInfo');
  sessionStorage.removeItem('lastLoggedIn');
  sessionStorage.removeItem('PREMIUM_LANDING_REDIRECT');
  sessionStorage.removeItem('creditScore'); // Also call API and remove Token. // Not as important since tokens are automatically removed after 900s of inactivity.
  removeItem('appPlatform');
  removeItem('havePreApprovedOffers');
  removeItem('hidePreApprovedBanner');
  removeItem('OFFER_TILES_ORDER');
  removeItem('VRI_RANDOM');
  removeItem('PREMIUM_MEMBER_TRIAL');
  clearResurrectedUser();
  clearSesameCashCookies();
  clearCStrack(); //clear items from csweb
  clearRefCode();
  clearMarketingRefCode();
  clearUsrTkn();
  clearAcknowledgePrescreen();
  clearSnackBar();
  cleanupSignupItems();
  clearCSContext();
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
  });
  return standardizedFetch(apiUrls.logout(), requestOptions).then(
    handleLogoutResponse,
  );
}

export async function postOcfSession(
  userId,
  ocfName,
  ocfRef,
  signature,
  prescreen,
  sessionIdentifier,
  token,
  pageName,
) {
  const payload = { apiKey: getSlapiApiKey() };
  if (userId && ocfRef) {
    Object.assign(payload, {
      userId: userId,
      ocfName: ocfName,
      ocfRef: ocfRef,
      signature: signature,
      prescreen: prescreen,
    });
  }
  if (token && pageName) {
    Object.assign(payload, { token: token, page: pageName });
  }
  if (sessionIdentifier) {
    //if session tracking already exists, continue using same tracking id
    Object.assign(payload, { sessionIdentifier });
  }
  const body = JSON.stringify(payload);
  const fetchOpts = buildFetchOptionsWithHeaders({ method: 'POST', body });

  return standardizedFetchHandler(
    buildOcfSessionEndpoint(prescreen),
    fetchOpts,
  );
}

export async function getSessionStatus() {
  const fetchOpts = buildFetchOptionsWithHeaders();
  return standardizedFetchHandler(buildUserSessionStatus(), fetchOpts);
}

export async function postChatSession(body) {
  const fetchOpts = buildFetchOptionsWithHeaders({ method: 'POST', body });
  return standardizedFetchHandler(buildChatSessionEndpoint(), fetchOpts);
}

export const postSessionAccessToken = async accessToken => {
  const payload = { apiKey: getSlapiApiKey() };
  const body = JSON.stringify(payload);
  const customHeaders = {};
  Object.assign(customHeaders, { authorization: 'Bearer ' + accessToken });
  const requestOptions = buildFetchOptionsWithHeaders(
    { method: 'POST', body },
    customHeaders,
  );

  return standardizedFetchHandler(buildSessionEndpoint(true), requestOptions);
};
