import {
  buildFetchOptionsWithHeaders,
  convertObjectToQueryString,
  standardizedFetchHandler,
} from './service_helpers';
import {
  buildCreditCardEndpoint,
  buildSingleCreditCardEndpoint,
  buildSingleCreditCardReviewsEndpoint,
  buildCreditCardReviewsInventoryEndpoint,
  buildPersonalLoanEndpoint,
  buildPersonalLoanPreapprovalEndpoint,
  buildSinglePersonalLoanEndpoint,
  buildSinglePersonalLoanReviewsEndpoint,
  buildCreditCardPreapprovalEndpoint,
  buildAutoLoanEndpoint,
  buildOffersEndpoint,
  buildCreditRepairEndpoint,
  buildRewardsCalculationEndpoint,
  buildSingleAutoLoanEndpoint,
  buildMortgageEndpoint,
  buildPrequalFormDataEndpoint,
  buildDebtReliefEndpoint,
  buildPostCreditCardReviewEndpoint,
  buildPostPersonalLoanReviewsEndpoint,
  buildInsuranceOffersEndpoint,
  buildBusinessLoanEndpoint,
  buildVerticalFormDataEndpoint,
  buildInsuranceUserPrefillEndpoint,
  buildOfferTrackingEncodedLink,
} from 'api-urls';
import { CREDIT_CARD_CONTAINER } from 'partial/OfferManager/offer.constants';
import { getItem } from 'helpers/localstorage';
import { getOffersRequestParameters } from 'helpers/offer.helpers';

export async function fetchMarketplaceCreditCards(queryOptions) {
  const fetchOpts = buildFetchOptionsWithHeaders();
  if (
    queryOptions?.observedApprovalOddsValue?.includes('HIGHEST_APPROVAL_ODDS')
  ) {
    queryOptions.observedApprovalOddsValue = [
      ...queryOptions?.observedApprovalOddsValue.filter(
        value => value !== 'HIGHEST_APPROVAL_ODDS',
      ),
      'OUTSTANDING',
      'EXCELLENT',
      'GREAT',
      'PRE_APPROVED',
    ];
  }
  const queryString = convertObjectToQueryString(queryOptions);
  return standardizedFetchHandler(
    buildCreditCardEndpoint() + queryString,
    fetchOpts,
  );
}

export async function fetchInsurances(queryOptions) {
  const fetchOpts = buildFetchOptionsWithHeaders();
  const queryString = convertObjectToQueryString(queryOptions);

  return standardizedFetchHandler(
    buildInsuranceOffersEndpoint() + queryString,
    fetchOpts,
  );
}

export async function putInsuranceUserPrefill(userPrefill) {
  const fetchOpts = buildFetchOptionsWithHeaders({
    method: 'PUT',
    body: userPrefill,
  });

  return standardizedFetchHandler(
    buildInsuranceUserPrefillEndpoint(),
    fetchOpts,
  );
}

export async function fetchVerticalFormData(queryOptions) {
  const fetchOpts = buildFetchOptionsWithHeaders();
  const queryString = convertObjectToQueryString(queryOptions);

  return standardizedFetchHandler(
    buildVerticalFormDataEndpoint() + queryString,
    fetchOpts,
  );
}

export async function fetchCreditCardsByContainer(queryOptions) {
  const container = queryOptions.container;

  if (!container || container === '') {
    queryOptions.container = CREDIT_CARD_CONTAINER;
  }
  queryOptions.clientType = 'WEB';
  queryOptions.userId = 'me';

  const fetchOpts = buildFetchOptionsWithHeaders();
  const queryString = convertObjectToQueryString(queryOptions);

  return standardizedFetchHandler(
    buildOffersEndpoint() + queryString,
    fetchOpts,
  );
}

export async function fetchCreditCardById(productId, options) {
  const paramsFromFlag = getOffersRequestParameters();

  const fetchOpts = buildFetchOptionsWithHeaders();
  const hardCodedGetOptions = {
    userId: 'me',
    details: 'true',
    guaranteeBadge: paramsFromFlag?.guaranteeBadge,
  };
  const finalQueryOptions = options
    ? Object.assign({}, hardCodedGetOptions, options)
    : hardCodedGetOptions;
  const queryString = convertObjectToQueryString(finalQueryOptions);

  return standardizedFetchHandler(
    buildSingleCreditCardEndpoint(productId) + queryString,
    fetchOpts,
  );
}

export async function fetchCreditCardReviewsById(
  productId,
  numReviews,
  filterCriteria,
) {
  const fetchOpts = buildFetchOptionsWithHeaders();
  const hardCodedGetOptions = {
    userId: 'me',
    itemsPerPage: numReviews > 0 ? numReviews : 10,
    ...filterCriteria,
  };

  const queryString = convertObjectToQueryString(hardCodedGetOptions);

  return standardizedFetchHandler(
    buildSingleCreditCardReviewsEndpoint(productId) + queryString,
    fetchOpts,
  );
}

export async function fetchMarketplacePersonalLoans(queryOptions) {
  const fetchOpts = buildFetchOptionsWithHeaders();

  const storedLoanAmount = getItem('DEFAULT_PERSONAL_LOAN_AMOUNT');
  let loanAmount = storedLoanAmount ? Number(storedLoanAmount) : 5000;
  const finalOptions = Object.assign({ loanAmount }, queryOptions);
  const queryString = convertObjectToQueryString(finalOptions);

  return standardizedFetchHandler(
    buildPersonalLoanEndpoint() + queryString,
    fetchOpts,
  );
}

export async function fetchPersonalLoanPreApprovals(queryOptions) {
  const fetchOpts = buildFetchOptionsWithHeaders();
  const queryString = convertObjectToQueryString(queryOptions);

  return standardizedFetchHandler(
    buildPersonalLoanPreapprovalEndpoint() + queryString,
    fetchOpts,
  );
}

export async function fetchCreditCardPreApprovals(queryOptions) {
  const fetchOpts = buildFetchOptionsWithHeaders();
  const queryString = convertObjectToQueryString(queryOptions);

  return standardizedFetchHandler(
    buildCreditCardPreapprovalEndpoint() + queryString,
    fetchOpts,
  );
}

export async function fetchPersonalLoanById(productId, creditScore) {
  const fetchOpts = buildFetchOptionsWithHeaders();
  const storedLoanAmount = getItem('DEFAULT_PERSONAL_LOAN_AMOUNT');
  let loanAmount = storedLoanAmount ? Number(storedLoanAmount) : 5000;
  if (Number.isNaN(loanAmount)) loanAmount = 5000;
  const queryString = convertObjectToQueryString({
    loanAmount,
    creditScore,
    userId: 'me',
  });

  return standardizedFetchHandler(
    buildSinglePersonalLoanEndpoint(productId) + queryString,
    fetchOpts,
  );
}

export async function fetchPersonalLoanReviewsById(
  providerId,
  numReviews,
  filterCriteria,
) {
  const fetchOpts = buildFetchOptionsWithHeaders();
  let finalNumReviews = numReviews > 0 ? numReviews : 10;
  const queryString = convertObjectToQueryString({
    providerId: providerId,
    itemsPerPage: finalNumReviews,
    ...filterCriteria,
  });

  return standardizedFetchHandler(
    buildSinglePersonalLoanReviewsEndpoint() + queryString,
    fetchOpts,
  );
}

export async function fetchCreditRepairOffers(options) {
  const fetchOpts = buildFetchOptionsWithHeaders();
  const queryString = convertObjectToQueryString(options);

  return standardizedFetchHandler(
    buildCreditRepairEndpoint() + queryString,
    fetchOpts,
  );
}

export async function fetchAutoLoanOffers(queryOptions) {
  const fetchOpts = buildFetchOptionsWithHeaders();
  const queryString = convertObjectToQueryString(queryOptions);

  return standardizedFetchHandler(
    buildAutoLoanEndpoint() + queryString,
    fetchOpts,
  );
}

export async function fetchAutoLoanById(lenderId) {
  const fetchOpts = buildFetchOptionsWithHeaders();

  return standardizedFetchHandler(
    buildSingleAutoLoanEndpoint(lenderId),
    fetchOpts,
  );
}

export async function fetchRewardsSavings(requestBody) {
  const finalBody = requestBody ? JSON.stringify(requestBody) : null;
  const requestOptions = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: finalBody,
  });

  return standardizedFetchHandler(
    buildRewardsCalculationEndpoint(),
    requestOptions,
  );
}

export async function fetchMortgageOffers(queryOptions) {
  const fetchOpts = buildFetchOptionsWithHeaders();
  const queryString = convertObjectToQueryString(queryOptions);

  return standardizedFetchHandler(
    buildMortgageEndpoint() + queryString,
    fetchOpts,
  );
}

export async function fetchDebtReliefProducts() {
  const requestOptions = buildFetchOptionsWithHeaders();
  return standardizedFetchHandler(buildDebtReliefEndpoint(), requestOptions);
}

export async function fetchPrequalFormData(vertical, lender) {
  const requestOptions = buildFetchOptionsWithHeaders();
  const url = buildPrequalFormDataEndpoint(vertical);
  const lenderParam = lender ? `&lender=${lender}` : ''; //optional field
  return standardizedFetchHandler(`${url}${lenderParam}`, requestOptions);
}

export async function postCreditCardReview(creditCardId, reviewObject) {
  const fetchOpts = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: reviewObject,
  });
  const url = buildPostCreditCardReviewEndpoint(creditCardId);

  return standardizedFetchHandler(url, fetchOpts);
}

export async function postPersonalLoanReview(
  personalLoanLenderId,
  reviewObject,
) {
  const fetchOpts = buildFetchOptionsWithHeaders({
    method: 'POST',
    body: reviewObject,
  });
  const url = buildPostPersonalLoanReviewsEndpoint(personalLoanLenderId);

  return standardizedFetchHandler(url, fetchOpts);
}

export async function fetchMarketplaceBusinessLoans(queryOptions) {
  const fetchOpts = buildFetchOptionsWithHeaders();
  const queryString = convertObjectToQueryString(queryOptions);

  return standardizedFetchHandler(
    buildBusinessLoanEndpoint() + queryString,
    fetchOpts,
  );
}

export async function fetchOfferTrackingEncodedLink(encodedLink) {
  const fetchOpts = buildFetchOptionsWithHeaders();
  return standardizedFetchHandler(
    buildOfferTrackingEncodedLink(encodedLink),
    fetchOpts,
  );
}

export async function fetchCreditCardReviewsInventory() {
  const fetchOpts = buildFetchOptionsWithHeaders();

  return standardizedFetchHandler(
    buildCreditCardReviewsInventoryEndpoint(),
    fetchOpts,
  );
}
