import {
  validatePasswordCase1,
  validatePasswordCase2,
  validatePasswordCase3,
  validatePasswordCase4,
  validatePhoneUS,
} from 'helpers/signup.helpers';
import * as Yup from 'yup';

export const WELCOME_VERIFICATION = 'WELCOME_VERIFICATION';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const EDIT_EMAIL = 'EDIT_EMAIL';
export const CONFIRM_PHONE = 'CONFIRM_PHONE';
export const EDIT_PHONE = 'EDIT_PHONE';
export const CREATE_PASSWORD = 'CREATE_PASSWORD';
export const VERIFY_OTP_EMAIL = 'VERIFY_OTP_EMAIL';
export const VERIFY_OTP_PHONE = 'VERIFY_OTP_PHONE';

export const PROGRESS_BARS = {
  EMAIL: { currentStep: 1, stepCount: 3 },
  PHONE: { currentStep: 2, stepCount: 3 },
  PASSWORD: { currentStep: 3, stepCount: 3 },
};

export const MESSAGES = {
  DUPLICATE_EMAIL:
    'The email address you entered is already linked to an existing account. To create a new account, please use a different email address. Why am I seeing a duplicate email address error?',
  DUPLICATE_PHONE: 'Phone number already exists.',
};

export const CREATE_PASSWORD_INITIAL_VALUES = {
  password: '',
  confirmPassword: '',
};

export const EDIT_EMAIL_INITIAL_VALUES = {
  email: '',
  confirmEmail: '',
};

export const EDIT_PHONE_INITIAL_VALUES = {
  contactPhoneNumber: '',
  contactPhoneNumberConfirmed: '',
};

export const CREATE_PASSWORD_SCHEMA = Yup.object({
  password: Yup.string()
    .required()
    .test('min-length', null, value => validatePasswordCase1(value))
    .test('uppercase', null, value => validatePasswordCase2(value))
    .test('lowercase', null, value => validatePasswordCase3(value))
    .test('number', null, value => validatePasswordCase4(value)),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export const EDIT_EMAIL_SCHEMA = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Email does not match')
    .required('Confirm your email'),
});

export const EDIT_PHONE_SCHEMA = Yup.object({
  contactPhoneNumber: Yup.string()
    .test('validate-phone', 'Please enter a valid phone number', value => {
      const errorMessage = validatePhoneUS(value);
      return errorMessage === false;
    })
    .required('Phone number is required'),
  contactPhoneNumberConfirmed: Yup.string()
    .oneOf([Yup.ref('contactPhoneNumber'), null], 'Phone numbers must match')
    .required('Confirm your phone number'),
});
