import { WELCOME_VERIFICATION } from 'pages/AccountVerification/utils/AccountVerification.utils';
import {
  RESET_ACCOUNT_VERIFICATION,
  SET_ACCOUNT_VERIFICATION_DATA,
} from 'types/accountVerification.constants';
import { LOGOUT } from 'types/app.constants';

const initialState = {
  step: WELCOME_VERIFICATION,
  email: '',
  messageError: '',
  emailConfirmed: '',
  duplicatedEmail: '',
  contactPhoneNumber: '',
  contactPhoneNumberConfirmed: '',
  duplicatedContactPhoneNumber: '',
  progressBar: { currentStep: 1, stepCount: 3 },
};

export default function accountVerificationReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case SET_ACCOUNT_VERIFICATION_DATA: {
      return { ...state, ...action };
    }
    case LOGOUT:
    case RESET_ACCOUNT_VERIFICATION:
      return initialState;
    default:
      return state;
  }
}
